<template>
  <Transition>
    <div class="slider">
      <v-range-slider
        v-model="slider"
        color="#14CCC9"
        track-size="2"
        width="100"
        :max="sliderState.rangeMax ?? 1_000_000"
        :min="0"
        :step="step"
      >
        <template v-slot:prepend>
          <v-text-field
            v-model="slider[0]"
            hide-details
            type="number"
            placeholder="min"
            class="slider-input slider-min"
            variant="outlined"
            density="compact"
            style="width: 80px"
          ></v-text-field>
        </template>
        <template v-slot:append>
          <v-text-field
            v-model="slider[1]"
            hide-details
            type="number"
            placeholder="max"
            class="slider-input slider-min"
            variant="outlined"
            style="width: 80px"
            density="compact"
          ></v-text-field>
        </template>
      </v-range-slider>
      <div class="button">
        <div class="btn">
          <MaireButton text="Apply" comfortable @click="handleApply" />
        </div>
        <div class="btn">
          <MaireButton text="Clear" comfortable inverse @click="handleClear" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.btn {
  display: block;
  float: right;
  padding: 24px 0px 0px 12px;
}
.slider {
  width: 100%;
  padding: 24px;
  overflow: hidden;
}
</style>
<script lang="ts">
import { RangeSliderState } from "@/store/modules/analysis/types";
import { defineComponent } from "vue";
import { PropType } from "vue";
import MaireButton from "../ButtonBlock/MaireButton.vue";

export default defineComponent({
  name: "RangeSlider",
  emits: ["change", "save"],
  props: {
    sliderState: { type: Object as PropType<RangeSliderState>, required: true },
  },
  watch: {
    slider: {
      handler: "onValueChange",
      deep: true,
    },
  },
  data: () => ({
    slider: [undefined, undefined] as Array<number | undefined>,
  }),
  mounted() {
    if (this.sliderState) {
      if (this.sliderState.min) {
        this.slider[0] = this.sliderState.min || 0;
      }
      if (this.sliderState.max) {
        this.slider[1] = this.sliderState.max;
      }
    }
  },
  computed: {
    step() {
      if (this.sliderState?.rangeMax && this.sliderState?.rangeMax < 150) {
        return 1;
      }
      return Math.floor(this.sliderState?.rangeMax / 1000) || 20;
    },
  },
  methods: {
    handleApply() {
      this.$emit("save", this.slider);
    },
    handleClear() {
      this.slider = [undefined, undefined];
      // @ts-ignore
      this.onValueChange([undefined, undefined]);
      this.$emit("save", this.slider);
    },
    onValueChange(values: string[] | number[]) {
      const valuesAsNumbers = values.map((value) => (typeof value === "string" ? parseInt(value) : value));
      this.$emit("change", valuesAsNumbers);
    },
  },
  components: { MaireButton },
});
</script>
