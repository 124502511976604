<template>
  <div v-if="label" class="container" id="label-view">
    <v-container>
      <MaireDialog
        title="Update label rules"
        description=""
        :open="updateModalOpen"
        :fn="saveLabel"
        :onClose="() => (updateModalOpen = false)"
      >
        <v-row :key="key" v-for="[key, rule] in Object.entries(rules)">
          <v-col cols="6">
            <p style="min-width: 300px">{{ key }}</p>
            <MaireChipsInput
              :color="key !== 'includeAnyLabels' ? maireBlue : undefined"
              field="text"
              :items="unwrapRule(key)"
              hide-input
              :closable="false"
            />
          </v-col>
          <v-col cols="6">
            <p :class="ruleChanged(key) ? 'strong' : ''" :style="ruleChanged(key) ? mairePurple : ''">
              New rules
            </p>
            <MaireChipsInput
              :color="key !== 'includeAnyLabels' ? maireBlue : undefined"
              field="text"
              :items="unwrapRule(key, rule)"
              hide-input
              :closable="false"
            />
          </v-col> </v-row
        >>
      </MaireDialog>
      <v-row>
        <div class="inline" style="width: 100%; padding-bottom: 40px">
          <v-breadcrumbs
            :items="[
              {
                title: 'Label management',
                disabled: false,
                href: '/labels',
              },
              {
                title: label.text,
                disabled: true,
                href: '/labels',
              },
            ]"
          ></v-breadcrumbs>
          <div style="float: left" class="checkbox" v-if="labelId && label?.rules">
            <MaireTooltip>
              <template #trigger>
                <div id="label-pill" style="display: flex; height: 40px">
                  <div class="inline center" style="padding: 0px 4px">
                    <v-icon size="20">far fa-clock</v-icon>
                  </div>
                  <div class="inline center" style="padding: 0px 4px">
                    <v-checkbox
                      density="dense"
                      @update:modelValue="toggleLabelActivity"
                      hide-details
                      v-model="isActive"
                    />
                  </div>
                </div>
              </template>
              <template #content>
                <strong style="color: white">Autolabelling</strong>
                <p style="color: white">
                  When selected, the rule will periodically check for keywords matching the rule and apply the
                  label.
                </p>
              </template>
            </MaireTooltip>
          </div>
          <div style="float: right">
            <div v-if="labelId" style="display: inline-block; vertical-align: middle; margin-right: 12px">
              <MaireButton
                text="Delete label"
                icon="far fa-trash"
                compact
                inverse
                :onClick="() => (deletionModalOpen = true)"
              />
            </div>
            <div style="display: inline-block; vertical-align: middle; margin-right: 12px" v-if="labelId">
              <MaireButton
                icon="far fa-eye"
                inverse
                text="Fetch preview"
                :disabledMessage="
                  !validRule ? 'Add some rules, otherwise it will match everything in your analyses.' : ''
                "
                :disabled="previewFetchInProgress || !validRule"
                :onClick="fetchRulePreview"
                compact
              />
            </div>
            <div style="display: inline-block; vertical-align: middle; margin-right: 12px" v-if="labelId">
              <MaireButton
                icon="far fa-robot"
                inverse
                text="Trigger labelling"
                :disabled="!(totalCount && labelId)"
                :isLoading="labelingInProgress"
                disabledMessage="Fetch preview first to ensure that you're able to capture the keywords you need."
                :onClick="triggerLabelRule"
                compact
              />
            </div>
            <div v-if="labelId" style="display: inline-block; vertical-align: middle">
              <MaireButton
                :disabled="!totalCount"
                disabledMessage="Fetch preview first to ensure that you're able to capture the keywords you need."
                text="Save"
                :onClick="() => (updateModalOpen = true)"
                compact
              />
            </div>
            <div v-else style="display: inline-block; vertical-align: middle">
              <MaireButton
                disabledMessage="Fetch preview first to ensure that you're able to capture the keywords you need."
                text="Save"
                :onClick="createLabel"
                compact
              />
            </div>
          </div>
        </div>
      </v-row>
      <v-row>
        <v-col cols="4">
          <p class="s strong">Label name</p>
          <div class="label-name-input inputs nopadding pt-2">
            <v-text-field
              hide-details
              density="compact"
              type="string"
              variant="outlined"
              v-model="labelName"
              required
            >
            </v-text-field>
            <div style="max-width: 100px; margin-top: 4px">
              <maire-button
                @click="saveName"
                compact
                v-if="labelName !== label.text && labelId"
                text="Save name"
              />
            </div>
          </div>
          <p class="s strong my-2">Label color</p>
          <div class="color-picker">
            <v-color-picker mode="hex" v-model="color"> </v-color-picker>
            <div style="max-width: 100px; margin-top: 4px">
              <maire-button @click="saveColor" compact v-if="colorDifferent && labelId" text="Save color" />
            </div>
          </div>
        </v-col>
        <v-col cols="4" v-if="labelId">
          <div :key="index" v-for="(rule, index) in includeKeywordRules?.length ? includeKeywordRules : [[]]">
            <p v-if="index === 0" class="s strong">Include keywords that contain at least one of the words</p>
            <div v-else>
              <MaireTooltip>
                <template #trigger><p class="s strong">and at least one of the words</p></template>
                <template #content
                  ><p style="color: white">
                    In addition to the rule(s) above, the keywords must also contain any of the words in this
                    rule.
                  </p>
                  <p class="strong" style="color: white; margin: 8px 0px">Example</p>
                  <p style="color: white">
                    If you have the word "jacket" in the above rule, and you add "winter" into this rule. The
                    search term must match both of these. E.g. "winter jacket" will match but "summer jacket"
                    wont.
                  </p></template
                >
              </MaireTooltip>
            </div>
            <MaireChipsInput
              label="Include keywords with"
              :items="rule"
              :color="maireBlue"
              @add="(val: string) => handleAdd('includeKeywordRules', val, index)"
              @remove="(val: string) => handleRemove('includeKeywordRules', val, index)"
            />
          </div>
          <div class="add-configuration" @click="addNewConfiguration">
            <v-icon>fat fa-plus-circle</v-icon><span>Add new rule</span>
          </div>
          <div class="exclude-section">
            <div
              :key="index"
              v-for="(rule, index) in excludeKeywordRules?.length ? excludeKeywordRules : [[]]"
            >
              <p v-if="index === 0" class="s strong">Exclude keywords that contain at least one of the words</p>
              <div v-else>
                <MaireTooltip>
                  <template #trigger><p class="s strong">and at least one of the words</p></template>
                  <template #content
                    ><p style="color: white">
                      In order for a keyword to be excluded, in addition to the rule(s) above, the keywords must also contain at least one of the words in this rule.
                    </p>
                    <p class="strong" style="color: white; margin: 8px 0px">Example</p>
                    <p style="color: white">
                      If you have the word "storm" in the above rule, and you add "winter" into this rule.
                      The search term must match both of these to be excluded. E.g. "winter storm" will be excluded but "summer
                      storm" wont.
                    </p></template
                  >
                </MaireTooltip>
              </div>
              <MaireChipsInput
                label="Exclude keywords with"
                :items="rule"
                :color="maireBlue"
                @add="(val: string) => handleAdd('excludeKeywordRules', val, index)"
                @remove="(val: string) => handleRemove('excludeKeywordRules', val, index)"
              />
            </div>
          </div>
          <div class="add-configuration" @click="addNewExcludeConfiguration">
            <v-icon>fat fa-plus-circle</v-icon><span>Add new rule</span>
          </div>
        </v-col>
        <v-col cols="4" v-if="labelId">
          <div>
            <p class="s strong">Only add to keywords with label</p>
            <!-- <p class="xs">
              You can add labels to include the keywords that contain the following labels, to also include
              the label being created.
            </p> -->
            <MaireChipsInput
              label="Keywords with label"
              :itemList="labels"
              :items="restrictLabels"
              @add="handleRestrictLabelAdd"
              @remove="(val: Label) => handleRemove('restrictLabels', val)"
              field="text"
            />
          </div>
          <div class="inputs mt-5 nopadding">
            <p class="s strong mb-5">Select location and language</p>
            <div
              density="comfortbale"
              style="
                display: inline-block;
                width: 50% !important;
                background-color: white;
                border-radius: 10px;
              "
            >
              <v-autocomplete
                prepend-inner-icon="fa-regular fa-location-dot"
                density="compact"
                v-model="localization.location_name"
                :items="SUPPORTED_COUNTRIES"
                label="Location"
                variant="outlined"
                hide-details
                clearable
              >
              </v-autocomplete>
            </div>
            <div
              class="inputs nopadding"
              style="
                display: inline-block;
                width: 49% !important;
                background-color: white;
                border-radius: 10px;
                margin-left: 1%;
              "
            >
              <v-autocomplete
                prepend-inner-icon="fa-regular fa-language"
                density="compact"
                v-model="localization.language_code"
                :items="SUPPORTED_LANGUAGES"
                label="Language"
                variant="outlined"
                hide-details
                clearable
              >
              </v-autocomplete>
            </div>
          </div>
          <div class="mt-5">
            <p class="s strong">Shown in the following analyses</p>
            <!-- <p class="xs">
              This label is included in the below analyses. You can remove it from a specific analysis if you
              think it shouldn’t be there.
            </p> -->
            <div style="margin-top: -12px">
              <MaireChipsInput
                :color="mairePurpleSecondary"
                :items="label.analyses"
                hide-input
                field="name"
                :closable="false"
              />
            </div>
          </div>
          <div class="mt-5" style="width: 100%">
            <p class="s strong mb-5">Volume filtering</p>
            <MaireSelect
              :handleSave="() => {}"
              @change="handleVolumeChange"
              :sliderState="volumeRange"
              :numberFormatter="numberFormatter"
              type="slider"
              title="Volume"
              :showSelect="false"
              transparent
            />
          </div>
          <div class="mt-5 maire-drop-shadow paper-background">
            <p class="s strong">
              <strong>In addition to the other rules</strong>, label all keywords that have the following
              labels.
            </p>
            <!-- <p class="xs">
              You can add labels to include the keywords that contain the following labels, to also include
              the label being created.
            </p> -->
            <MaireChipsInput
              label="Keywords with label"
              :itemList="unselectedLabels"
              :items="includeAnyLabels"
              @add="handleLabelAdd"
              @remove="(val: Label) => handleRemove('includeAnyLabels', val)"
              field="text"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <MaireDialog
      :onClose="() => (deletionModalOpen = false)"
      :open="deletionModalOpen"
      description="Are you sure you
  want to delete this label? It will remove it from all keywords and analyses. This cannot be undone!"
      confirmText="Delete"
      :fn="deleteLabel"
    />
    <div class="label-list maire-scrollbar" v-if="totalCount > 0 || previewFetchInProgress">
      <p class="s mb-5" v-if="!previewFetchInProgress">
        Total number of matching keywords: <strong>{{ totalCount }}</strong>
      </p>
      <div class="search_container">
        <SearchBar @set="searchWordChanged" />
      </div>
      <p v-if="totalCount > 10000" class="s strong my-2">Sample</p>
      <div class="table-content">
        <DataTable
          :items="filteredPreviewData"
          :isLoading="previewFetchInProgress"
          :fields="fields"
          :actions="actions"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <p>
      H'mm. This place shouldn't exist. Did you navigate here directly? If not, please let someone at Maire
      know and we'll see what went wrong.
    </p>
  </div>
</template>
<style scoped>
.search_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0px;
  width: 400px;
  float: right;
}
.checkbox {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 1);
  border-radius: 5px;
  padding: 0px 8px;
  color: white;
  margin-left: 14px;
}
.highlight {
  background-color: rgba(var(--v-theme-mairePurple), 1);
}
.exclude-section {
  margin-top: 48px;
}
.add-configuration {
  color: rgba(var(--v-theme-mairePurple));
  cursor: pointer;
}
.add-configuration span {
  margin-left: 12px;
}
.label-name-input span {
  color: black;
}
.container {
  margin: 12px 24px;
}
.label-list {
  width: 100%;
  padding: 50px 24px;
  border-radius: 0px !important;
}
.box {
  background-color: rgb(var(--v-theme-mairePurpleSecondary), 0.1);
  height: 250px;
  margin-bottom: 12px;
}
</style>
<script lang="ts" setup>
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { computed, ref, watch, onMounted, Ref } from "vue";
import MaireSelect from "@/components/MaireSelect/MaireSelect.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import MaireChipsInput from "@/components/MaireChipsInput/MaireChipsInput.vue";
import { Field } from "@/types";
import KeywordCell from "@/components/DataTable/DataTableCells/KeywordCell.vue";
import { countryISOCode } from "@/components/KeywordTable/KeywordTable.vue";
import VolumeCell from "@/components/KeywordTable/VolumeCell.vue";
import LabelCell from "@/components/DataTable/DataTableCells/LabelCell/LabelCell.vue";
import { Label, LabelRuleDetails } from "@/store/modules/labels/types";
import { useLabelColor } from "@/composables/useLabelColor";
import { SUPPORTED_COUNTRIES, SUPPORTED_LANGUAGES } from "@/components/helpers/geo_constants";
import { keywordMatchesSearchWord, numberFormatter } from "@/components/helpers/helpers";
import { RangeSliderState } from "@/store/modules/analysis/types";
import MaireTooltip from "@/components/MaireTooltip/MaireTooltip.vue";
import SearchBar from "@/components/DataTable/SearchBar.vue";
import { Keyword } from "@/store/modules/keywords/types";

const handleVolumeChange = (value: number[]) => {
  if (value?.length === 2) {
    volumeRange.value.min = value[0];
    volumeRange.value.max = value[1];
  }
};

const searchWordChanged = (word: string) => {
  searchWord.value = word;
};
const volumeRange = ref({
  min: undefined,
  max: undefined,
  rangeMin: 0,
  rangeMax: 1_000_000,
} as RangeSliderState);

const { getLabelColor } = useLabelColor();
const store = useStore();
const fields = [
  {
    field: "text",
    name: "Keyword",
    component: () => KeywordCell,
    width: 16,
    aggregation: "count_distinct",
    tooltip: "Search term on Google",
  },
  {
    field: "location_name",
    name: "Loc",
    renderer: (value: any) => countryISOCode(value).toUpperCase(),
    width: 5,
    tooltip: "Search location",
  },
  {
    field: "language_code",
    name: "Lang",
    renderer: (value: any) => value.toUpperCase(),
    width: 5,
    tooltip: "Search language",
  },
  {
    field: "labels",
    name: "Labels",
    width: 16,
    component: () => LabelCell,
  },
  {
    field: "volume",
    name: "Volume",
    component: () => VolumeCell,
    aggregation: "sum",
    width: 8,
    tooltip: "Average monthly searches on Google.",
  },
  {
    field: "google_only_volume",
    name: "Google only volume",
    component: () => VolumeCell,
    aggregation: "sum",
    width: 8,
    tooltip: "Average monthly searches on Google.",
  },
] as Field[];

// interface MatchRule {
//   value: string;
//   fullMatch: boolean;
// }
const route = useRoute();
const router = useRouter();
const isActive = ref(false);
const updateModalOpen = ref(false);
const deletionModalOpen = ref(false);
const previewFetchInProgress = ref(false);
const includeAnyKeywords = ref([] as string[]);
const includeKeywordRules = ref([] as string[][]);
const excludeKeywordRules = ref([] as string[][]);
const includeAnyLabels = ref([] as Label[]);
const restrictLabels = ref([] as Label[]);
const includeAllKeywords = ref([] as string[]);
const localization = ref({
  location_name: undefined,
  language_code: undefined,
});
const excludeAnyKeywords = ref([] as string[]);
const mairePurple = "rgb(var(--v-theme-mairePurple)";
const mairePurpleSecondary = "rgb(var(--v-theme-mairePurpleSecondary)";
const maireBlue = "rgb(var(--v-theme-maireDarkBlue)";

const rules = computed(() => ({
  includeAnyKeywords: includeAnyKeywords.value,
  includeAnyLabels: includeAnyLabels.value.map((label) => label.id).filter(Boolean),
  restrictLabels: restrictLabels.value.map((label) => label.id).filter(Boolean),
  includeAllKeywords: includeAllKeywords.value,
  excludeAnyKeywords: excludeAnyKeywords.value,
  includeKeywordRules: includeKeywordRules.value?.filter((rl) => rl?.length),
  excludeKeywordRules: excludeKeywordRules.value.filter((rl) => rl?.length),
  localizations:
    localization.value?.location_name || localization.value?.language_code ? [localization.value] : undefined,
  volumeRange: volumeRange.value?.min || volumeRange.value?.max ? [volumeRange.value] : undefined,
}));
const previewData: Ref<Keyword[]> = ref([]);
const searchWord = ref("");
const filteredPreviewData = computed(() => {
  return previewData.value.filter((kw: any) => {
    return keywordMatchesSearchWord({ keyword: kw.text, labels: kw.labels } as Keyword, searchWord.value);
  });
});
const totalCount = ref(0);
const labelingInProgress = computed(() => store.state.loading.labelingInProgress);
const validRule = computed(() => Object.values(rules.value).some((val) => val?.filter(Boolean).length));
const fetchRulePreview = async () => {
  previewFetchInProgress.value = true;
  const result = await store.dispatch("labels/labelRulePreview", rules.value);
  previewFetchInProgress.value = false;
  previewData.value = result.results;
  totalCount.value = result.total_count;
};
const dummyLabel = {
  id: undefined,
  text: "New Label",
  color: undefined,
  keyword_count: undefined,
  analyses: undefined,
  rules: undefined,
};
const labelId = computed(() => parseInt(route.params.id as string) as number);
const labels = computed(() => Object.values(store.state.labels.labels));
const unselectedLabels = computed(() => {
  const selected = includeAnyLabels.value.map((lbl) => lbl.id);
  return labels.value.filter((lbl) => !selected.includes(lbl.id));
});
const label = computed(() => {
  return labelId.value ? labels.value.find((lbl) => lbl.id === labelId.value) : dummyLabel;
});
const labelName = ref("");
const color = ref("");
const colorDifferent = computed(() => color.value !== getLabelColor(label.value?.text ?? ""));
const toggleLabelActivity = async (val: boolean) => {
  if (label.value?.id) {
    await store.dispatch("labels/toggleLabelRule", {
      labelId: label.value?.id,
      isActive: val,
      labelText: label.value.text,
    });
  }
};
const colorTimer = ref(0);
watch(labelName, () => {
  if (colorTimer.value) clearTimeout(colorTimer.value);
  if (!label.value?.color) {
    colorTimer.value = setTimeout(
      () => (color.value = labelName.value ? getLabelColor(labelName.value) : ""),
      500
    ) as unknown as number;
  }
});
watch(
  label,
  async () => {
    // Check if in current local state rules include the just changed label
    const labelInFilter = includeAnyLabels.value.find((lbl) => lbl.id);
    if (
      labelInFilter &&
      (labelInFilter.text !== label.value?.text || labelInFilter.color !== label.value?.color)
    ) {
      includeAnyLabels.value = includeAnyLabels.value.map((lbl) =>
        lbl.id === label.value?.id ? { ...lbl, text: label.value.text, color: label.value.color } : lbl
      );
    }

    // To have a nifty backgward-compatibility to ensure all previously labeled kws are part of the new logic
    // We automatically add a rule that the label-rule matches all keywords which alredy have the label.
    // This basically means that it will never delete the unlabeled keywords.
    if (labelId.value && label.value && !label.value?.rules) {
      includeAnyLabels.value = [label.value];
    }
    labelName.value = label.value?.text ?? "";

    // Use custom label color if set
    if (label.value?.color) {
      color.value = label.value.color;
    } else {
      color.value = label.value?.text ? getLabelColor(label.value?.text) : "";
    }
  },
  { immediate: true }
);
onMounted(async () => {
  if (!labelId.value) return;
  const r = await store.dispatch("labels/fetchRules", labelId.value);
  const labelRule = r.length > 0 ? r[0] : undefined;
  if (!labelRule) return;
  includeAnyKeywords.value = labelRule?.rule?.includeAnyKeywords ?? [];
  includeAnyLabels.value = labelRule?.rule?.includeAnyLabels ?? [];
  restrictLabels.value = labelRule?.rule?.restrictLabels ?? [];
  includeAllKeywords.value = labelRule?.rule?.includeAllKeywords ?? [];
  excludeAnyKeywords.value = labelRule?.rule?.excludeAnyKeywords ?? [];
  includeKeywordRules.value = labelRule?.rule?.includeKeywordRules ?? [];
  excludeKeywordRules.value = labelRule?.rule?.excludeKeywordRules ?? [];
  volumeRange.value = labelRule?.rule?.volumeRange?.[0] ?? {};
  localization.value = labelRule?.rule?.localizations?.[0] ?? {
    location_name: undefined,
    language_code: undefined,
  };
  if (!labelRule?.rule?.includeKeywordRules?.length && includeAllKeywords?.value?.length) {
    includeAllKeywords.value.forEach((val) => includeKeywordRules.value.push([val]));
  } else {
    includeKeywordRules.value = labelRule?.rule?.includeKeywordRules ?? [];
  }
  if (!labelRule?.rule?.excludeKeywordRules?.length && excludeAnyKeywords?.value?.length) {
    excludeKeywordRules.value = [excludeAnyKeywords.value];
  } else {
    excludeKeywordRules.value = labelRule?.rule?.excludeKeywordRules ?? [];
  }
  isActive.value = labelRule?.is_active ?? false;
});
const actions = [
  {
    icon: "fa-regular fa-trash",
    name: "Delete",
    fn: () => {
      deletionModalOpen.value = true;
    },
  },
];

const handleLabelAdd = (val: number) => {
  const labelToAdd = labels.value.find((lbl) => lbl.id === val);
  if (labelToAdd) {
    includeAnyLabels.value.push(labelToAdd);
  }
};
const handleRestrictLabelAdd = (val: number) => {
  const labelToAdd = labels.value.find((lbl) => lbl.id === val);
  if (labelToAdd) {
    restrictLabels.value.push(labelToAdd);
  }
};
const handleAdd = (type: string, val: string, index?: number) => {
  if (type === "includeKeywordRules" && index !== undefined) {
    if (!includeKeywordRules.value?.[index]) {
      includeKeywordRules.value[index] = [];
    }
    !includeKeywordRules.value?.[index]?.includes(val) && includeKeywordRules.value?.[index].push(val);
  } else if (type === "excludeKeywordRules" && index !== undefined) {
    if (!excludeKeywordRules.value?.[index]) {
      excludeKeywordRules.value[index] = [];
    }
    !excludeKeywordRules.value?.[index]?.includes(val) && excludeKeywordRules.value?.[index].push(val);
  }
};
const handleRemove = (type: string, item: string | Label, index?: number) => {
  if (type === "includeAnyKeywords") {
    includeAnyKeywords.value = includeAnyKeywords.value.filter((val) => val !== item);
  } else if (type === "includeAllKeywords") {
    includeAllKeywords.value = includeAllKeywords.value.filter((val) => val !== item);
  } else if (type === "excludeAnyKeywords") {
    excludeAnyKeywords.value = excludeAnyKeywords.value.filter((val) => val !== item);
  } else if (type === "includeAnyLabels" && typeof item === "object") {
    includeAnyLabels.value = includeAnyLabels.value.filter((val) => val.id !== item?.id);
  } else if (type === "restrictLabels" && typeof item === "object") {
    restrictLabels.value = restrictLabels.value.filter((val) => val.id !== item?.id);
  } else if (type === "includeKeywordRules" && index !== undefined) {
    includeKeywordRules.value[index] = includeKeywordRules.value?.[index]?.filter((val) => val !== item);
  } else if (type === "excludeKeywordRules" && index !== undefined) {
    excludeKeywordRules.value[index] = excludeKeywordRules.value?.[index]?.filter((val) => val !== item);
  }
};
const deleteLabel = async () => {
  deletionModalOpen.value = false;
  if (label.value) {
    await store.dispatch("labels/deleteLabels", [label.value.text]);
    router.push("/labels");
  }
};
const saveLabel = async () => {
  updateModalOpen.value = false;
  if (label.value?.id) {
    await store.dispatch("labels/createLabelRule", {
      labelId: label.value?.id,
      labelText: label.value.text,
      rules: rules.value,
    });
  }
};
const createLabel = async () => {
  const lbl = await store.dispatch("labels/createLabel", {
    text: labelName.value,
    color: color.value,
  });
  if (lbl) {
    router.push(`/labels/${lbl.id}`);
  }
};

const triggerLabelRule = async () => {
  await store.dispatch("labels/triggerLabelRule", {
    labelId: label.value?.id,
    rules: rules.value,
  });
};
const saveColor = async () => {
  await store.dispatch("labels/updateLabel", { label: label.value, color: color.value });
};
const saveName = async () => {
  await store.dispatch("labels/updateLabel", { label: label.value, text: labelName.value });
};
const ruleChanged = (key: string) => {
  return (
    JSON.stringify(unwrapRule(key)) !==
    JSON.stringify(unwrapRule(key, rules.value?.[key as keyof LabelRuleDetails]))
  );
};
const unwrapRule = (key: string, rule?: Array<Label | string | number | Array<string>>) => {
  rule = rule ?? label.value?.rules?.[0]?.rule?.[key as keyof LabelRuleDetails];
  if (["includeAnyLabels", "restrictLabels"].includes(key)) {
    return rule?.map((labelId) => labels.value.find((lbl) => lbl.id === labelId));
  } else if (key === "volumeRange") {
    // @ts-ignore
    return rule?.map((range) => `${range.min} - ${range.max}`);
  } else if (key === "localizations") {
    // @ts-ignore
    return rule?.map(
      (localization) =>
        // @ts-ignore
        `${localization.location_name} ${localization.language_code ? `(${localization.language_code})` : ""}`
    );
  } else if (["includeKeywordRules", "excludeKeywordRules"].includes(key) && typeof rule == "object") {
    // @ts-ignore
    return rule?.map((strArr) => strArr.join("|"));
  } else {
    return rule;
  }
};

const addNewConfiguration = () => {
  if (!includeKeywordRules.value.length) {
    includeKeywordRules.value = [[]];
  }
  includeKeywordRules.value.push([]);
};
const addNewExcludeConfiguration = () => {
  if (!excludeKeywordRules.value.length) {
    excludeKeywordRules.value = [[]];
  }
  excludeKeywordRules.value.push([]);
};
</script>
