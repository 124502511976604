import {
  AnalysisFilters,
  CheckboxState,
  LabelFilter,
  LevelKeywordLabels,
} from "@/store/modules/analysis/types";
import { Keyword, Volumes } from "@/store/modules/keywords/types";
import { viewLabelsToStructure } from "@/store/modules/labels/helpers";
import { ViewLabel } from "@/store/modules/labels/types";

export const toUSD = (value: number) =>
  new Intl.NumberFormat("fi-FI", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  }).format(value);

export const formatNumber = (value: number) =>
  new Intl.NumberFormat("fi-FI", {
    maximumFractionDigits: 0,
  }).format(value);

export const numberFormatter = (num: number, digits: number, includePlusSymbol: boolean = false) => {
  let isNegative = false;
  if (num && num < 1) {
    isNegative = true;
    // return num.toFixed(digits);
  }
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return Math.abs(num) >= item.value;
    });
  const value = item ? (Math.abs(num) / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  return isNegative ? `-${value}` : includePlusSymbol ? `+${value}` : value;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getLabelColor2 = (label: string) => {
  let hash = 0;
  for (let i = 0; i < label?.length; i++) {
    hash = label.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color =
    ((hash >> 24) & 0xff).toString(16) +
    ((hash >> 16) & 0xff).toString(16) +
    ((hash >> 8) & 0xff).toString(16) +
    (hash & 0xff).toString(16);
  return "#" + ("000000" + color).slice(-6);
};

export const keywordMatchesSearchWord = (keyword: Keyword, searchWord: string, extraString?: string) => {
  searchWord = searchWord.trim().toLowerCase();
  let stringToSearch = keyword.keyword.toLowerCase();
  stringToSearch +=
    keyword.labels
      ?.map((lbl) => lbl.text)
      .join(" ")
      .toLowerCase() || "";
  if (extraString) {
    stringToSearch += extraString.toLowerCase();
  }
  // exclusion check, return false if the keyword contains the exclusion word
  // const re = RegExp(/(-(\w+))/g);
  const re = RegExp(/-(?:"([^"]+)"|[\p{L}\d_]+)/gu);
  const exclusionWords = searchWord.match(re);
  let excludeAllWithLabels = false;
  if (exclusionWords) {
    for (let i = 0; i < exclusionWords.length; i++) {
      const exclusionWord = exclusionWords[i];
      searchWord = searchWord.replace(exclusionWord, "");
      const exclusionCleaned = exclusionWord.slice(1).replaceAll('"', "");
      if (exclusionCleaned === "exclude_all_with_labels") {
        excludeAllWithLabels = true;
      }
      if (stringToSearch.includes(exclusionCleaned)) {
        return false;
      }
    }
  }
  if (excludeAllWithLabels && keyword.labels?.length) {
    return false;
  }

  // If there are no other rules, return true
  searchWord = searchWord.trim();
  // Only support AND or OR search, but not both
  if (searchWord.includes("&")) {
    // AND search, if any of the AND words are not found, return false
    const andWords = searchWord.split("&");
    for (let i = 0; i < andWords.length; i++) {
      const andWord = andWords[i];
      if (!stringToSearch.includes(andWord)) {
        return false;
      }
    }
  } else {
    // OR search, if none of the OR words are found, return false
    const orWords = searchWord.split("|");
    let containsOrWord = false;
    for (let i = 0; i < orWords.length; i++) {
      const orWord = orWords[i];
      if (stringToSearch.includes(orWord)) {
        containsOrWord = true;
        break;
      }
    }
    if (!containsOrWord) {
      return false;
    }
  }

  return true;
};

export const filterKeywords = (
  keywords: Keyword[],
  filters: AnalysisFilters,
  returnSet?: boolean,
  levelKwMap?: LevelKeywordLabels
) => {
  const kwLabelMap = {} as { [key: number]: Set<string> };
  // Build a map of keyword id to labels from all levels
  Object.values(levelKwMap || {})
    .flat()
    ?.forEach((kw) => {
      if (kwLabelMap[kw.id]) {
        kw.labels.forEach((label: ViewLabel) => kwLabelMap[kw.id].add(label.text));
      } else {
        kwLabelMap[kw.id] = new Set(kw.labels.map((label: ViewLabel) => label.text));
      }
    });
  const countryCbs = filters.countryFilter?.filter((cb) => cb.checked);
  const languageCbs = filters.languageFilter?.filter((cb) => cb.checked);
  const trendingCbs = filters.trendingFilter?.filter((cb) => cb.checked);
  const includeFilter = filters.labelFilters?.include;
  const selectedIncludeLabels = includeFilter.labels.filter((label: CheckboxState) => label.checked);
  const labelFiltersByLevel: { [key: number]: CheckboxState[] } = {};
  for (const label of selectedIncludeLabels) {
    if (!label.level) return;
    if (!labelFiltersByLevel[label.level]) {
      labelFiltersByLevel[label.level] = [];
    }
    labelFiltersByLevel[label.level].push(label);
  }
  if (Object.keys(labelFiltersByLevel).length > 0) {
    // AND rule between levels, and OR rule within levels.
    // So if there's a label checked on the first level, all keywords must have that label
    // If there's 3 labels selected on the second level, any of those labels must be present in the keyword AND the keyword must have the label from the first level
    keywords = keywords.filter((keyword: Keyword) => {
      const kwLabels = kwLabelMap[keyword.id];
      if (!kwLabels) return false;
      const kwLabelsArr = Array.from(kwLabels);
      const levels = Object.keys(labelFiltersByLevel).map((level) => parseInt(level));
      if (
        !levels.every((level) =>
          kwLabelsArr.some((label) => labelFiltersByLevel[level].map((cb) => cb.text).includes(label))
        )
      ) {
        return false;
      }
      return true;
    });
  }
  const kwSet = new Set<Keyword>();
  const filteredKws = keywords.filter((keyword: Keyword) => {
    // country filter
    if (countryCbs?.length > 0) {
      const countryNames = countryCbs.map((cb) => cb.text);
      if (!countryNames.includes(keyword.localization?.location_name)) {
        return false;
      }
    }
    // language filter
    if (languageCbs?.length > 0) {
      const languageNames = languageCbs.map((cb) => cb.text);
      if (!languageNames.includes(keyword.localization?.language_code)) {
        return false;
      }
    }
    // trending filter
    if (trendingCbs?.length > 0) {
      // Trending is a percentage that can range from -100 to infinity
      const trendingNames = trendingCbs.map((cb) => cb.value);
      if (!trendingNames.includes("declining") && keyword.trending <= 0) {
        return false;
      }
      if (!trendingNames.includes("on_the_rise") && keyword.trending > 0 && keyword.trending < 25) {
        return false;
      }
      if (!trendingNames.includes("on_fire") && keyword.trending >= 25 && keyword.trending < 50) {
        return false;
      }
      if (!trendingNames.includes("rocketing") && keyword.trending >= 50) {
        return false;
      }
    }

    // Search word filter
    if (filters.searchWord) {
      // If the keyword or none of the labels contain the search word, return false
      if (keywordMatchesSearchWord(keyword, filters.searchWord) === false) {
        return false;
      }
    }

    // Volume range filter
    if (filters.volumeRange?.min && keyword.volume < filters.volumeRange?.min) {
      return false;
    }
    if (filters.volumeRange?.max && keyword.volume > filters.volumeRange?.max) {
      return false;
    }

    // Position range filter
    if (filters.positionRange?.min && keyword.position < filters.positionRange.min) {
      return false;
    }
    if (filters.positionRange?.max && keyword.position > filters.positionRange.max) {
      return false;
    }
    if (returnSet) kwSet.add(keyword);
    return true;
  });
  return returnSet ? kwSet : filteredKws;
};

export const calculateVolumeForTimeFrame = (startDate: Date, endDate: Date, kws: Keyword[]): number => {
  if (endDate < startDate) {
    return 0;
  }
  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth();
  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth(); // 0-indexed
  let currentYear = startYear;
  let currentMonth = startMonth;
  let safeGuard = 0;
  let sum = 0;
  while (currentYear < endYear || (currentYear === endYear && currentMonth <= endMonth)) {
    const volume = kws.reduce((prev: number, curr: Keyword) => {
      return prev + curr.volumes?.[currentYear]?.[currentMonth] || 0;
    }, 0);
    sum += volume;
    if (currentMonth > 11) {
      currentYear++;
      currentMonth = 0;
    } else {
      currentMonth++;
    }
    safeGuard++;
    if (safeGuard > 100) {
      console.error("Safe guard triggered, too many months to analyze");
      break;
    }
  }

  return sum;
};

const monthsBefore = (date: Date, months: number) => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() - months);
  return newDate;
};

const findLastMonth = (arr: (number | null)[]) => {
  if (!arr) return -1;
  for (let i = arr.length - 1; i >= 0; i--) {
    if (typeof arr[i] === "number") {
      return i + 1;
    }
  }
  return -1; // If no numerical value is found, return -1
};
export const calculateStatsForKeywordGroupNew = (
  kws: Keyword[],
  label: string,
  latestYear: number,
  latestMonth: number
) => {
  const count = kws.length;
  const lastDate = new Date(latestYear, latestMonth - 1, 15);
  const last12MonthsVolume = calculateVolumeForTimeFrame(monthsBefore(lastDate, 11), lastDate, kws);
  const comp12MonthsVolume = calculateVolumeForTimeFrame(
    monthsBefore(lastDate, 23),
    monthsBefore(lastDate, 12),
    kws
  );
  const lastOneMonthVolume = calculateVolumeForTimeFrame(monthsBefore(lastDate, 0), lastDate, kws);
  let growthRolling = 0;
  let growthRollingAbsolute = 0;
  const isNew = kws[0]?.is_new;
  growthRolling =
    comp12MonthsVolume > 0
      ? ((last12MonthsVolume - comp12MonthsVolume) / comp12MonthsVolume) * 100
      : last12MonthsVolume > 0
      ? 100
      : 0;
  growthRollingAbsolute = last12MonthsVolume - comp12MonthsVolume;

  return {
    label,
    volume: last12MonthsVolume,
    growth: growthRolling, // Rolling 12 months
    absoluteGrowth: growthRollingAbsolute, // Rolling 12 months Absolute
    isNew, // New keyword
    count, // Number of keywords in this group
    lastOneMonthVolume, // Used for new keywords
  };
};

export const calculateStatsForKeywords = (kws: Keyword[], latestYear: number, latestMonth: number) => {
  const labelKeywordMap = {} as { [key: string]: Keyword[] };
  // Label is just the keywords keyword
  for (let i = 0; i < kws.length; i++) {
    const keyword = kws[i];
    labelKeywordMap[keyword.keyword] = [keyword];
  }
  const data = Object.keys(labelKeywordMap).map((label: string) => {
    return {
      ...calculateStatsForKeywordGroupNew(labelKeywordMap[label], label, latestYear, latestMonth),
      trending: labelKeywordMap[label]?.[0]?.trending,
    };
  });
  return data;
};

export const aggregateVolumesForKeywords = (keywords: Keyword[]) => {
  const volumes = {} as { [key: string]: Array<number> };
  for (let i = 0; i < keywords.length; i++) {
    const kw = keywords[i];
    Object.entries(kw.volumes).forEach(([year, monthVolumes]) => {
      if (!volumes[year]) {
        volumes[year] = Array(12).fill(null);
      }
      for (let j = 0; j < 12; j++) {
        if (monthVolumes[j] !== null) {
          volumes[year][j] += monthVolumes[j];
        }
      }
    });
  }
  return volumes;
};

export const calculateLabelStats = (keywords: Keyword[], labels: string[]) => {
  const labelKeywordMap = {} as { [key: string]: Keyword[] };
  const volumes = {} as { [key: string]: { [key: string]: Array<number> } };
  let latestYear = 0;
  let latestMonth = 0;
  let firstYear = 9999;
  let firstMonth = 12;
  for (let i = 0; i < keywords.length; i++) {
    const keyword = keywords[i];
    // Calculate the latest year and month among all keywords to ensure we compare the same time periods
    if (keyword?.volumes) {
      const years = Object.keys(keyword.volumes).map((year) => parseInt(year));
      const maxYearInKeyword = Math.max(...years);
      const minYearInKeyword = Math.min(...years);
      if (minYearInKeyword < firstYear) {
        firstYear = minYearInKeyword;
        firstMonth = keyword.volumes[minYearInKeyword].findIndex((volume) => volume !== null) + 1;
      } else if (minYearInKeyword === firstYear) {
        const firstMonthInKeyword =
          keyword.volumes[minYearInKeyword].findIndex((volume) => volume !== null) + 1;
        if (firstMonthInKeyword < firstMonth) {
          firstMonth = firstMonthInKeyword;
        }
      }
      if (maxYearInKeyword == latestYear && latestMonth < 11) {
        latestMonth = findLastMonth(keyword.volumes[maxYearInKeyword]);
      } else if (maxYearInKeyword > latestYear) {
        latestYear = Math.max(latestYear, maxYearInKeyword);
        latestMonth = findLastMonth(keyword.volumes[maxYearInKeyword]);
      }
    } else if (keyword.volumes_from) {
      const year = parseInt(keyword.volumes_from.slice(0, 4));
      const month = parseInt(keyword.volumes_from.slice(4, 6));
      if (year > latestYear) {
        latestYear = year;
        latestMonth = month;
      } else if (year === latestYear && month > latestMonth) {
        latestMonth = month;
      }
    }
    for (let j = 0; j < keyword?.labels?.length ?? 0; j++) {
      const label = keyword.labels[j];
      if (!labelKeywordMap[label.text]) {
        labelKeywordMap[label.text] = [];
      }
      labelKeywordMap[label.text].push(keyword);
    }
  }
  const data = labels.map((label: string) => {
    volumes[label] = aggregateVolumesForKeywords(labelKeywordMap[label]);
    return calculateStatsForKeywordGroupNew(labelKeywordMap[label], label, latestYear, latestMonth);
  });
  const keywordData = calculateStatsForKeywords(keywords, latestYear, latestMonth);
  const keywordVolumes = {} as { [keyword: string]: Volumes };
  keywordData.forEach((kw) => {
    keywordVolumes[`${kw}`];
  });
  return {
    labelStats: data,
    keywordStats: keywordData,
    volumes,
    latestYear,
    latestMonth,
    firstYear,
    firstMonth,
  };
};

export const fixFilters = (filters: AnalysisFilters, kws: Keyword[], viewLabels: ViewLabel[]) => {
  if (!filters) {
    filters = {
      countryFilter: [],
      languageFilter: [],
      volumeRange: { rangeMin: 0, rangeMax: 0 },
      trendingFilter: [],
      labelFilters: {
        include: {
          labels: [],
        },
      },
      searchWord: "",
      positionRange: { rangeMin: 0, rangeMax: 0 },
    };
  }
  const structure = viewLabelsToStructure(viewLabels);
  const labelToLevelMap = {} as { [key: string]: number };
  Object.keys(structure).forEach((level: string) => {
    structure[parseInt(level)].forEach((label) => {
      labelToLevelMap[label.text] = parseInt(level);
    });
  });
  const countries = new Set<string>();
  const languages = new Set<string>();
  const kwIds = new Set<number>();
  let maxVolume = -Infinity;
  let minVolume = Infinity;
  let newVolumeFields = {};
  let maxPotential = -Infinity;
  let minPotential = Infinity;
  let needsToUpdate = false;
  const trendingNameValueMap: { [key: string]: any } = {
    "Rocketing +50%": "rocketing",
    "On fire +25-50%": "on_fire",
    "On the rise +1-25%": "on_the_rise",
    Declining: "declining",
  };
  const trendingNameIconMap: { [key: string]: any } = {
    "Rocketing +50%": "fa-regular fa-rocket",
    "On fire +25-50%": "fas fa-fire",
    "On the rise +1-25%": "fa-light fa-chart-mixed",
    Declining: "fa-regular fa-chart-line-down",
  };
  // Localization, volume and potential filters are updated based on the keywords
  kws.forEach((kw) => {
    if (kw.volume > maxVolume) maxVolume = kw.volume;
    if (kw.volume < minVolume) minVolume = kw.volume;
    if (kw.potential > maxPotential) maxPotential = kw.potential;
    if (kw.potential < minPotential) minPotential = kw.potential;
    countries.add(kw.localization.location_name);
    languages.add(kw.localization.language_code);
    kwIds.add(kw.id);
  });
  const countryFilter = Array.from(countries).map((country) => ({
    text: country,
    checked: false,
  }));
  const languageFilter = Array.from(languages).map((language) => ({
    text: language,
    checked: false,
  }));
  if (
    minVolume !== Infinity &&
    maxVolume !== -Infinity &&
    (filters.volumeRange.rangeMax !== maxVolume || filters.volumeRange.rangeMin !== minVolume)
  ) {
    newVolumeFields = {
      rangeMin: minVolume,
      rangeMax: maxVolume,
    };
  }
  let includeLabels = filters.labelFilters.include.labels as CheckboxState[];
  const labelsInStrure = Object.values(structure)
    .flat()
    .map((label) => label.text);
  const validCurrentIncludeLabels = filters.labelFilters.include.labels.filter((label) =>
    labelsInStrure.includes(label.text)
  );
  const missingLabels = labelsInStrure.filter(
    (label) => !validCurrentIncludeLabels.map((lbl) => lbl.text).includes(label)
  );
  const newLabels = missingLabels.map((label) => ({
    text: label,
    checked: false,
  }));
  if (newLabels.length > 0 || validCurrentIncludeLabels.length !== includeLabels.length) {
    needsToUpdate = true;
    includeLabels = [...validCurrentIncludeLabels, ...newLabels];
  }
  let trendingFilter = filters.trendingFilter;
  if (!trendingFilter?.length) {
    const newFilter = Object.entries(trendingNameValueMap).map(([name, key]) => {
      return {
        text: name,
        icon: trendingNameIconMap[name],
        value: key,
        checked: false,
      };
    });
    trendingFilter = newFilter;
  }
  const newFilter = {
    ...filters,
    countryFilter,
    languageFilter,
    volumeRange: { ...filters.volumeRange, ...newVolumeFields },
    trendingFilter: trendingFilter.map((cb) => {
      return {
        ...cb,
        text: cb.text,
        icon: trendingNameIconMap[cb.text] || "",
        value: trendingNameValueMap[cb.text] || "",
        checked: cb.checked,
      };
    }),
    labelFilters: {
      include: {
        labels: includeLabels.map((cb) => ({ ...cb, level: labelToLevelMap[cb.text] })),
      },
    },
  };
  return { filters: newFilter, needsToUpdate };
};

function partition(array: any[], low: number, high: number, field: string): number {
  const pivot = array[high][field];
  let i = low;
  for (let j = low; j < high; j++) {
    if (array[j][field] >= pivot) {
      [array[i], array[j]] = [array[j], array[i]];
      i++;
    }
  }
  [array[i], array[high]] = [array[high], array[i]];
  return i;
}

export function quickSelect(array: any[], k: number, field: string): any[] {
  let low = 0;
  let high = array.length - 1;

  while (low <= high) {
    const pivotIndex = partition(array, low, high, field);
    if (pivotIndex === k - 1) {
      break;
    } else if (pivotIndex > k - 1) {
      high = pivotIndex - 1;
    } else {
      low = pivotIndex + 1;
    }
  }

  const sorted = array.slice(0, k);
  sorted.sort((a, b) => b[field] - a[field]);
  return sorted;
}

export function isSorted(array: any[], field?: string): boolean {
  for (let i = 0; i < array.length - 1; i++) {
    if (field) {
      if (array[i][field] < array[i + 1][field]) {
        return false;
      }
    } else {
      if (array[i] < array[i + 1]) {
        return false;
      }
    }
  }
  return true;
}

export const recommendedLevelForFilters = (filters: AnalysisFilters, viewLabels?: ViewLabel[]): number => {
  if (!filters?.labelFilters) return 1;
  return recommendedLevelForLabelFilters(filters.labelFilters, viewLabels);
};

export const recommendedLevelForLabelFilters = (labelFilters: LabelFilter, viewLabels?: ViewLabel[]) => {
  if (!labelFilters?.include.labels?.filter((cb) => cb.checked)?.length) return 1;
  const labelLevelMap = {} as { [key: string]: number };
  if (viewLabels) {
    viewLabels.forEach((lbl) => (labelLevelMap[lbl.text] = lbl.level));
  }
  let maxInChecked = 0;
  let maxLevel = 1;
  for (const label of labelFilters.include.labels || []) {
    label.level = label.level ?? labelLevelMap?.[label.text];
    if (label.level) {
      if (label.level > maxLevel) {
        maxLevel = label.level;
      }
      if (label.checked && label.level > maxInChecked) {
        maxInChecked = label.level;
      }
    } else {
      return 1;
    }
  }
  return Math.min(maxInChecked + 1, maxLevel);
};

export const levelsNeeded = (viewLabels: ViewLabel[], filterLabels: Set<string>): number[] => {
  if (!filterLabels?.size || !viewLabels?.length) return [1];
  return Array.from(
    viewLabels.reduce((acc: Set<number>, curr: ViewLabel) => {
      if (filterLabels.has(curr.text)) {
        acc.add(curr.level);
      }
      return acc;
    }, new Set())
  );
};

export const includedLabelsAsText = (labelFilters: LabelFilter) =>
  labelFilters.include.labels.reduce((acc: Set<string>, curr: CheckboxState) => {
    if (curr.checked) {
      acc.add(curr.text);
    }
    return acc;
  }, new Set());
