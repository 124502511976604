import { createStore, Store, ActionContext, useStore as baseUseStore } from "vuex";
import { InjectionKey } from "vue";
import axios from "axios";
import * as api from "../api";
import router from "@/router";
import { BACKEND_PATH, userResponseToUser } from "@/helpers";
import * as Sentry from "@sentry/vue";
import analysis from "./modules/analysis/analysis";
import loading from "./modules/loading/loading";
import gsc from "./modules/gsc/gsc";
import labels from "./modules/labels/labels";
import { AnalysisState, CheckboxState, View } from "./modules/analysis/types";
import { LoadingState } from "./modules/loading/types";
import { ArticleState } from "./modules/article/types";
import { LabelsState, ViewLabel } from "./modules/labels/types";
import { GSCState, GSCUser } from "./modules/gsc/types";
import article from "./modules/article/article";
import { KeywordsState } from "./modules/keywords/types";
import keywords from "./modules/keywords/keywords";
import {
  fixFilters,
  includedLabelsAsText,
  levelsNeeded,
  recommendedLevelForFilters,
} from "@/components/helpers/helpers";
import sockets from "@/services/sockets";
import { generateExampleFilters } from "./modules/analysis/helpers";

const GENERIC_API_ERROR_MSG = "Apologies! Something went wrong. Please try to reload the page and try again.";

const FAILED_TO_LOG_IN =
  "Wrong credentials, please check again. If you've forgotten your credentials, click \"I've forgotten my password\"";

const FAILED_TO_LOG_OUT = "Log out failed. Maybe you're already logged out?";

interface FavoritedKeyword {
  id: number;
}

interface UserSearch {
  domain?: string;
  customer_average_purchase?: number;
  location?: string;
  language?: string;
  descriptive_words?: string[];
}

export interface UserConsent {
  allow_kw: boolean;
  allow_chat: boolean;
}
export interface User {
  id: number;
  firstName: string;
  lastName: string;
  isActive: boolean;
  type: string;
  email: string;
  avatar: string;
  company?: Company;
  google_auth: any;
  hash?: string;
  impersonator?: string;
  favorited_keywords: FavoritedKeyword[];
  searches: UserSearch[];
  categories?: string[];
  consent?: UserConsent;
  is_active?: boolean;
}

export interface CompanyUser {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  type: string;
}

export interface Company {
  id?: string;
  name?: string;
  domain?: string;
  users?: User[];
  wordSuggestions?: string[];
  credits_left?: number;
  credits_per_month?: number;
  active_users?: CompanyUser[];
}

export interface ChatMessage {
  role: string;
  content: string;
}

export interface PerformanceData {
  domain: string;
  domain_rank: number;
  page_rank: number;
  page: string;
  keyword: string;
  keyword_id: number;
  position: number;
  ctr: number;
  volume: number;
  clicks: number;
  potential_reached: number;
  date: string;
}

export interface GAProperty {
  account_name: string;
  account_id: string;
  name: string;
  property_id: string;
  is_default: boolean;
  user?: GSCUser;
}

export interface WidgetMap {
  [key: string]: Widget;
}

// change to enum
export enum WidgetType {
  BarChart = "BarChart",
  PieChart = "PieChart",
  TimelineChart = "TimelineChart",
}

export interface Widget {
  id: number;
  primaryField: string;
  type: WidgetType;
}

export interface AnalysisFilterLabel {
  localized_keyword_id: number;
  text: string;
}
export interface RootState {
  user: User;
  conversation: ChatMessage[];
  rightNavbarOpen: boolean;
  userSearch: UserSearch;
  company: Company;
  errorText: string;
  searchWord: string;
  successText: string;
  showToggles: boolean;
  performance_data?: PerformanceData[];
  socket?: WebSocket;
  ga_properties: GAProperty[];
  admin: {
    companies?: Company[];
  };
}

export interface State extends RootState {
  analysis: AnalysisState;
  loading: LoadingState;
  article: ArticleState;
  gsc: GSCState;
  keywords: KeywordsState;
  labels: LabelsState;
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();

export const initialState: RootState = {
  user: {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    avatar: "",
    isActive: true,
    type: "dummy",
    favorited_keywords: [],
    searches: [],
    categories: [],
    google_auth: null,
  },
  userSearch: {},
  rightNavbarOpen: false,
  conversation: [],
  showToggles: false,
  errorText: "",
  successText: "",
  searchWord: "",
  ga_properties: [],
  performance_data: undefined,
  socket: undefined,
  company: {
    domain: "",
    credits_left: undefined,
    credits_per_month: undefined,
  },
  admin: {},
};

export const mutations = {
  isCreatingKeyword: (state: State, loading: boolean) => (state.loading.isCreatingKeyword = loading),
  setRightNavbarOpen: (state: State, open: boolean) => (state.rightNavbarOpen = open),
  setCompany: (state: State, company: Company) => (state.company = company),
  setCompanies: (state: State, companies: Company[]) => (state.admin.companies = companies),
  setUserSearch: (state: State, search: UserSearch) => (state.userSearch = search),
  setCredits: (state: State, credits: number) => (state.company.credits_left = credits),
  setUser: (state: State, user: User) => (state.user = user),
  toggleUserActivity: (state: State, userId: number) => {
    state.admin.companies?.forEach((company) => {
      company.users?.forEach((user) => {
        if (user.id === userId) {
          user.is_active = !user.is_active;
        }
      });
    });
  },
  setSocket: (state: State, socket: WebSocket) => (state.socket = socket),
  setConversation: (state: State, conversation: ChatMessage[]) => (state.conversation = conversation),
  addMessageToConversation: (state: State, message: string) =>
    state.conversation.push({ role: "assistant", content: message }),
  setErrorText: (state: State, errorText: string) => (state.errorText = errorText),
  setSuccessText: (state: State, successText: string) => (state.successText = successText),
  setWordSuggestions: (state: State, suggestions: string[]) => (state.company.wordSuggestions = suggestions),
  removeWordSuggestion: (state: State, wordToRemove: string) => {
    if (state.company.wordSuggestions) {
      state.company.wordSuggestions = state.company.wordSuggestions.filter((word) => word !== wordToRemove);
    }
  },
  setShowToggles: (state: State, bool: boolean) => {
    state.showToggles = bool;
  },
  removeDescriptiveWord: (state: State, keyword: string) => {
    state.userSearch.descriptive_words = state.userSearch?.descriptive_words?.filter(
      (word: string) => word !== keyword
    );
  },
  removeAllDescriptiveWords: (state: State) => {
    if (state.userSearch?.descriptive_words) {
      state.userSearch.descriptive_words = [];
    }
  },
  addDescriptiveWord: (state: State, keyword: string) => {
    const words = state.userSearch?.descriptive_words || [];
    if (words.includes(keyword)) {
      state.userSearch.descriptive_words = words.filter((kw) => kw != keyword);
    } else if (words) {
      state.userSearch.descriptive_words?.push(keyword);
    } else {
      state.userSearch.descriptive_words = [keyword];
    }
  },
  setPerformanceData: (state: State, data: PerformanceData[]) => {
    state.performance_data = data;
  },
  setGAProperties: (state: State, data: GAProperty[]) => {
    state.ga_properties = data;
  },
};

export const actions = {
  async rightNavbarOpen({ commit }: ActionContext<State, RootState>, open: boolean) {
    commit("setRightNavbarOpen", open);
  },
  async categorizeKeywords(
    { dispatch, commit, state }: ActionContext<State, RootState>,
    props: { categories: string[]; kwType: string }
  ) {
    try {
      await dispatch("ensureSocket");
      const socket = state.socket;
      let kwIds;
      if (props.kwType !== "favorites") {
        kwIds = state.keywords.discoveryKeywords.map((kw) => kw.id);
      }
      socket?.send(
        JSON.stringify({
          type: "keyword_categorization",
          categories: props.categories,
          kw_ids: kwIds,
        })
      );
    } catch (e) {
      console.log(e);
    }
  },
  async saveConsent({ commit, state }: ActionContext<State, RootState>, consent: UserConsent) {
    const r = await api.saveConsent(consent);
    commit("setUser", { ...state.user, consent: consent });
    commit("setSuccessText", "Consent saved");
  },
  async selectDynamicFilter({ commit, dispatch, state }: ActionContext<State, RootState>) {
    if (!state.keywords.discoveryKeywords.length) {
      await dispatch("keywords/fetchRelatedKeywords");
    }
    const filter = {
      ...generateExampleFilters(),
      labels: [],
      id: undefined,
    };
    commit("analysis/setCurrentAnalysis", {
      id: undefined,
      name: "Dynamic",
      description: "Discovery filters",
    });
    commit("analysis/setCurrentFilters", filter);
  },
  async selectView(
    { commit, dispatch, state }: ActionContext<State, RootState>,
    params: { viewId: number; level?: number; labels?: Set<string> }
  ) {
    const { viewId } = params;
    console.log("Selecting View", params);
    commit("loading/addFilterToLoadingAnalysis", viewId);

    // First ensuring that we have analysis and view present
    let view = state.analysis.views?.[viewId];
    if (!view) {
      await dispatch("analysis/fetchAnalyses");
      view = state.analysis.views?.[viewId];
      if (!view) return;
    }
    const analysis = state.analysis.analyses.find((f) => f.id === view.analysis_filter_id);
    if (!analysis) return;
    if (!view.filters) {
      view.filters = generateExampleFilters();
    }
    // Ensure that viewlabels are fetched (structure)
    if (!state.labels.viewLabels?.[viewId]) {
      await dispatch("labels/fetchViewLabels", { viewId });
    }

    // Set current analysis
    commit("analysis/setCurrentAnalysis", {
      id: analysis.id,
      name: analysis.name,
      description: analysis.description,
      volume_field: view.volumeField,
      viewId: view.id,
      edit_access: analysis.edit_access,
    });
    const promises = [];
    const keywordFetch = async () => {
      const [keywordsRequest, volumesRequest] = await Promise.all([
        api.fetchFilterKeywords(analysis.id),
        api.fetchAnalysisKeywordVolumes(analysis.id),
      ]);
      for (const keyword of keywordsRequest.data) {
        keyword.volumes = volumesRequest.data[keyword.id] || {};
      }
      commit("analysis/addKeywordsToAnalysis", { filter_id: analysis.id, keywords: keywordsRequest.data });
      commit("keywords/setAnalysisKeywords", keywordsRequest.data);
    };
    // Retrieve analysis keywords if they haven't been fetched yet.
    if (!analysis.keywords || analysis.keywords.length === 0) {
      promises.push(keywordFetch());
    } else {
      commit("keywords/setAnalysisKeywords", analysis.keywords);
    }

    // Retrieve needed labels for different levels
    const viewLabels = state.labels.viewLabels?.[viewId];
    const recommendedLevel = recommendedLevelForFilters(view.filters, viewLabels);
    const level = params.level ?? recommendedLevel ?? 1;
    commit("analysis/setViewCurrentLevel", { view, level });

    const labels = params.labels ? new Set(params.labels) : includedLabelsAsText(view.filters.labelFilters);
    const levels = Array.from(new Set([...levelsNeeded(state.labels.viewLabels?.[viewId], labels), level]));

    for (const neededLevel of levels) {
      if (neededLevel && !(view.levelKwMap[neededLevel] || view.levelKwMap[neededLevel]?.length === 0)) {
        // Collect all level fetching promises in an array
        promises.push(
          api.fetchLabelsForLevel(viewId, neededLevel).then((labelPromise) => {
            commit("analysis/setKeywordLabelsForLevel", {
              viewId,
              level: neededLevel,
              keywords: labelPromise.data,
            });
          })
        );
      }
    }
    // Execute all level fetch promises simultaneously
    await Promise.all(promises);

    // Check that filters are up to date
    const { filters, needsToUpdate } = fixFilters(
      view.filters,
      analysis.keywords,
      state.labels.viewLabels?.[viewId]
    );
    commit("analysis/setViewFilters", { viewId, filters });
    // In case someone overrode labels in URL, we want to select them in the view, but not save those changes to the API
    const labelsInParams = params.labels;
    if (labelsInParams) {
      filters.labelFilters.include.labels.forEach((cb: CheckboxState) => {
        if (labelsInParams.has(cb.text)) {
          cb.checked = true;
        } else {
          cb.checked = false;
        }
      });
      filters.labelFilters.include.labels.sort(
        (a: CheckboxState, b: CheckboxState) => (a.checked ? -1 : 1) - (b.checked ? -1 : 1)
      );
    }
    commit("analysis/setCurrentFilters", filters);
    if (needsToUpdate) {
      if (view.id) {
        dispatch("analysis/updateView", {...view, filters: filters});
      } else {
        dispatch("analysis/createView", view);
      }
    }
    commit("keywords/setKeywordLabels", view.levelKwMap[level]);
    commit("loading/removeFilterFromLoadingAnalysis", viewId);
  },
  async ensureSocket({ dispatch, state }: ActionContext<State, RootState>) {
    let socket = state.socket;
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      socket = await dispatch("setupSocket");
      while (socket?.readyState === WebSocket.CONNECTING) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
    } else {
      console.log("Socket already open.");
    }
  },
  async clearErrorText({ commit }: ActionContext<State, RootState>) {
    commit("setErrorText", "");
  },
  async clearSuccessText({ commit }: ActionContext<State, RootState>) {
    commit("setSuccessText", "");
  },
  async setupSocket({ commit }: ActionContext<State, RootState>) {
    const socket = sockets.initialize(commit);
    commit("setSocket", socket);
    return socket;
  },
  async fetchUser({ commit, dispatch, state }: ActionContext<State, RootState>) {
    return new Promise((resolve, reject) => {
      api
        .me()
        .then(async (r) => {
          const user = userResponseToUser(r.data);
          commit("setUser", user);
          const companyData = r.data.company;
          const descriptive_words = companyData.descriptive_words?.split(",") || [];
          commit("setCompany", companyData);
          commit(
            "setUserSearch",
            r.data.searches?.[0] || {
              ...companyData,
              descriptive_words: descriptive_words,
            }
          );
          commit("gsc/setGSCSites", r.data.company?.gsc_sites);
          commit("setGAProperties", r.data.company?.ga_properties);
          dispatch("setSentryUser");
          dispatch("setupSocket");
          resolve({ user, companyData });
        })
        .catch((e) => reject(e));
    });
  },
  async fetchAllCompanies({ commit }: ActionContext<State, RootState>) {
    const r = await api.fetchCompanies();
    commit("setCompanies", r.data);
  },
  async toggleUserActivity({ commit }: ActionContext<State, RootState>, user: User) {
    api
      .toggleUserActivity(user.id)
      .then((data) => {
        commit("toggleUserActivity", user.id);
        commit("setSuccessText", "User activity toggled successfully");
      })
      .catch((e) => {
        commit("setErrorText", `User activity toggle failed: ${e}`);
      });
  },
  async setSentryUser({ state }: ActionContext<State, RootState>) {
    Sentry.setUser({
      username: `${state.user?.firstName} ${state.user?.lastName}`,
      email: state.user?.email,
    });
  },
  async setup({ commit, dispatch }: ActionContext<State, RootState>) {
    commit("setErrorText", "");
    try {
      await dispatch("fetchUser");
      dispatch("analysis/fetchAnalyses");
      dispatch("labels/fetchLabels");
    } catch (e) {
      commit("loading/setLoadingKeywords", false);
      commit("setErrorText", "");
    }
  },
  async fetch_performance({ commit }: ActionContext<State, RootState>, country: string) {
    commit("setPerformanceData", undefined);
    const r = await api.performance(country);
    commit("setPerformanceData", r.data);
  },

  async fetch_ga_properties({ commit }: ActionContext<State, RootState>) {
    try {
      const r = await api.ga_properties();
      commit("setGAProperties", r.data);
    } catch (e: any) {
      const msg = e?.response?.data || "Error fetching GA data.";
      console.log(e);
    }
  },
  async select_ga_property({ commit, dispatch }: ActionContext<State, RootState>, property_id: string) {
    const r = await api.select_ga_property(property_id);
    dispatch("sendSuccessMessage", "Successfully selected GA Property.");
    commit("setGAProperties", r.data);
  },
  async sendMessage({ commit }: ActionContext<State, RootState>, conversation: ChatMessage[]) {
    commit("loading/setMaireIsTyping", true);
    commit("setConversation", conversation);
    try {
      const r = await api.sendMessage(conversation);
      commit("setConversation", [...conversation, r.data]);
    } catch (e: any) {
      if (e?.response?.data === "Too much information to process, reduce chat size.") {
        commit("setConversation", [
          ...conversation,
          {
            role: "assistant",
            content: "Sorry, I can't handle that much information. Please try again with less text.",
          },
        ]);
      } else {
        commit("setConversation", [
          ...conversation,
          {
            role: "assistant",
            content: "Sorry, my connection is bad. Please try again later. :)",
          },
        ]);
      }
    }
    commit("loading/setMaireIsTyping", false);
  },
  async impersonate({ commit, dispatch }: ActionContext<State, RootState>, userId: number) {
    dispatch("clearEverything");
    const r = await api.loginAs(userId);
    if (r.status === 200) {
      commit("setUser", userResponseToUser(r.data));
    } else {
      commit("setErrorText", "Impersonation failed.");
    }
  },
  async addDescriptiveWord(
    { commit }: ActionContext<State, RootState>,
    params: { type: string; keyword: string }
  ) {
    commit("addDescriptiveWord", params.keyword);
    commit("removeWordSuggestion", params.keyword);
  },
  async removeDescriptiveWord(
    { commit }: ActionContext<State, RootState>,
    params: { type: string; keyword: string }
  ) {
    commit("removeDescriptiveWord", params.keyword);
  },
  async removeAllDescriptiveWords({ commit }: ActionContext<State, RootState>) {
    commit("removeAllDescriptiveWords");
    commit("setWordSuggestions", []);
  },
  async fetchWordSuggestions(
    { commit }: ActionContext<State, RootState>,
    params: { keyword: string; language: string }
  ) {
    commit("loading/setLoadingWordSuggestions", true);
    const response = await axios.get(
      `${BACKEND_PATH}/similar_keywords?keyword=${params.keyword}&language=${params.language}`
    );
    if (response.status === 200) {
      commit("setWordSuggestions", response.data);
    }
    commit("loading/setLoadingWordSuggestions", false);
  },
  async removeWordSuggestion(
    { commit }: ActionContext<State, RootState>,
    params: { type: string; suggestion: string }
  ) {
    commit("removeWordSuggestion", params.suggestion);
  },
  async clearEverything({ commit }: ActionContext<State, RootState>) {
    commit("loading/setLoadingKeywords", false);
    commit("loading/setLoadingApp", false);
    commit("keywords/setDiscoveryKeywords", []);
    commit("keywords/setAnalysisKeywords", []);
    commit("setPerformanceData", undefined);
    commit("setGAProperties", []);
    commit("keywords/setKeywordWithDetails", undefined);
    commit("analysis/setAnalyses", []);
    commit("analysis/setViews", []);
    commit("gsc/setGSCSites", []);
    commit("setGAProperties", []);
    commit("setUser", {});
    commit("setUserSearch", {});
    commit("article/clearArticle");
    commit("labels/setLabels", []);
    commit("setErrorText", "");
    commit("setSuccessText", "");
  },
  async magicSearch({ commit, state }: ActionContext<State, RootState>, searchPhrase: string) {
    commit("loading/setThinking", true);
    try {
      const response = await axios.post(`${BACKEND_PATH}/magic_search`, {
        keywords: state.keywords,
        search_phrase: searchPhrase,
      });
      if (response.status === 200) {
        commit("filterKeywords", response.data);
      }
    } catch (e: any) {
      commit("setErrorText", "Failed to fetch keyword predictions.");
    }
    commit("loading/setThinking", false);
  },
  async sendSuccessMessage({ commit }: ActionContext<State, RootState>, text: string) {
    commit("setSuccessText", text);
  },
  async sendErrorMessage({ commit }: ActionContext<State, RootState>, text: string) {
    commit("setErrorText", text);
  },
  async updateCredits({ commit }: ActionContext<State, RootState>, credits: number) {
    commit("setCredits", credits);
  },
  async updateUserSearch({ commit, dispatch }: ActionContext<State, RootState>) {
    commit("loading/setLoadingKeywords", true);
    commit("setErrorText", "");
    dispatch("keywords/fetchRelatedKeywords");
  },
  async reset_password({ commit, dispatch }: ActionContext<State, RootState>, params: { email: string }) {
    commit("loading/setEmailBeingSent", true);
    const response = await axios
      .post(`${BACKEND_PATH}/reset_password`, params)
      .then((data) => data)
      .catch((e) => e);
    if (response.status !== 200) {
      commit("setErrorText", GENERIC_API_ERROR_MSG);
    } else {
      commit(
        "setSuccessText",
        "If the email matches an user, we've sent an email with instructions to reset the password."
      );
    }
    commit("loading/setEmailBeingSent", false);
  },
  async change_password(
    { commit }: ActionContext<State, RootState>,
    params: { token: string; new_password: string }
  ) {
    const response = await axios
      .post(`${BACKEND_PATH}/change_password`, params)
      .then((data) => data)
      .catch((e) => e);
    if (response.status !== 200) {
      commit("setErrorText", GENERIC_API_ERROR_MSG);
      return;
    } else {
      commit("setSuccessText", "Password successfully changed");
      router.push("/login");
    }
  },
  async login(
    { commit, dispatch }: ActionContext<State, RootState>,
    params: { email: string; password: string; stayLoggedIn: boolean }
  ) {
    const response = await axios
      .post(`${BACKEND_PATH}/login`, params)
      .then((data) => data)
      .catch((e) => e);
    if (response.status !== 200) {
      commit("setErrorText", FAILED_TO_LOG_IN);
      return;
    } else {
      commit("setSuccessText", "Login succeeded.");
      dispatch("setup");
      router.push("/analysis");
    }
  },
  async oAuth(
    { commit, dispatch }: ActionContext<State, RootState>,
    params: { code: string; state: string; scope: string }
  ) {
    const response = await axios
      .get(`${BACKEND_PATH}/token`, { params })
      .then((data) => data)
      .catch((e) => e);
    if (response.status !== 200) {
      const msg = response.response?.data || "Authentication failed.";
      commit("setErrorText", msg);
      return;
    } else {
      commit("setSuccessText", "Authentication succeeded!");
      dispatch("setup");
      router.push("/settings");
    }
  },
  async logout({ commit, dispatch }: ActionContext<State, RootState>) {
    const response = await axios
      .post(`${BACKEND_PATH}/logout`)
      .then((data) => data)
      .catch((e) => e);
    if (response.status !== 200) {
      commit("setErrorText", FAILED_TO_LOG_OUT);
      return;
    } else {
      await dispatch("clearEverything");
      commit("setSuccessText", "Successfully logged out.");
      router.push("/login");
    }
  },
};

export const store = createStore<State>({
  modules: {
    analysis,
    loading,
    article,
    gsc,
    keywords,
    labels,
  },
  state: initialState as State,
  mutations,
  actions,
});

export function useStore() {
  return baseUseStore(key);
}
export { generateExampleFilters };
