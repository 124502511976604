<template>
  <div class="labelModal" v-if="!hideActions">
    <MaireDialog
      :onClose="() => (showModal = false)"
      :fn="addLabelToKeywords"
      title="Add label"
      :description="`Add label to ${selectedItems.length} keyword(s)`"
      confirmText="Add label"
      :open="showModal"
      :confirmDisabled="!labelToAdd"
    >
      <div class="inputs dark">
        {{ labelToAdd }}
        <v-combobox
          variant="outlined"
          :items="analysisLabels"
          item-value="text"
          item-title="text"
          placeholder="Cameras, Blue, High intent"
          autofocus
          class="category-input-field preventClick labelInput"
          density="compact"
          hide-details
          v-model="labelToAdd"
        />
      </div>
    </MaireDialog>
  </div>
  <div class="removeFromAnalysisModal" v-if="!hideActions">
    <MaireDialog
      :onClose="() => (showRemovalModal = false)"
      :fn="removeKeywordsFromAnalysis"
      title="Remove keyword"
      :description="`Remove ${selectedItems.length} keywords from this analysis.`"
      :open="showRemovalModal"
      confirmText="Remove from analysis"
    >
      <div>
        <span>Which analysis you want to remove the keywords from?</span>
        <MaireSelect
          :items="analysisFilters.filter((af) => af.id && af.edit_access)"
          type="select"
          field="name"
          :selectedItem="selectedAnalysis?.name"
          :handleSave="handleFilterSelect"
          :disabled="isAnalysisView"
        />
      </div>
    </MaireDialog>
  </div>
  <div class="addToAnalysisModal" v-if="!hideActions">
    <MaireDialog
      :onClose="() => (showAnalysisModal = false)"
      :fn="addKeywordsToAnalysis"
      title="Add to analysis"
      :description="`Add ${selectedItems.length} keyword(s) to a saved analysis.`"
      confirmText="Add to analysis"
      :open="showAnalysisModal"
      :confirmDisabled="selectedItems.length === 0 || !selectedAnalysis?.id"
    >
      <div style="color: purple">
        <span>Which analysis you want to add the keywords to?</span>
        <MaireSelect
          :items="analysisFilters.filter((af) => af.id && af.edit_access)"
          type="select"
          field="name"
          :selectedItem="selectedAnalysis?.name"
          :handleSave="handleFilterSelect"
          :disabled="isAnalysisView"
        />
      </div>
    </MaireDialog>
  </div>
  <div class="newAnalysisModal" v-if="!hideActions">
    <div v-if="showNewAnalysisModal">
      <CreateAnalysisFilterModal
        :open="showNewAnalysisModal"
        :filters="filters"
        :onSave="handleAnalysisSave"
        :kwIds="selectedItemIds"
        @close="showNewAnalysisModal = false"
      />
    </div>
  </div>
  <div v-if="isLoading" class="animation">
    <wait-animation />
  </div>
  <!-- <div class="m strong" style="margin-bottom: 12px">Filters and sorting</div> -->
  <div v-if="!isLoading" class="table table-container">
    <v-row class="item-header-row paper-background">
      <div v-if="actions" style="padding: 8px"></div>
      <div class="checkbox" style="margin-left: 4px" v-if="!hideActions">
        <v-checkbox @click="toggleAll" :model-value="allSelected" color="purple" />
      </div>
      <div
        :style="`position:relative; flex-basis: ${field.width}%`"
        :class="`item-header cell cell-${field.field} ${
          columnsWithFilters.includes(field.field)
            ? 'has-filter'
            : '' || visibleFilter === field.field
            ? 'has-filter'
            : ''
        }`"
        v-for="field in filteredFields"
        :key="field.name"
      >
        <Transition>
          <div class="filter" v-if="allowFiltering(field.field) && visibleFilter === field.field">
            <KeywordFilter
              @save="afterFilterSave"
              :type="field.field"
              :showSelect="false"
              :items="allowedLocalFilters.includes(field.field) ? items : undefined"
              :field="allowedLocalFilters.includes(field.field) ? field : undefined"
              :selected="allowedLocalFilters.includes(field.field) ? localFilters?.[field.field] : undefined"
            />
          </div>
        </Transition>
        <div class="header-title-content">
          <v-icon class="sort-icon icon-left" @click="() => sortItems(field.field)">{{
            `${
              sortColumn === field.field
                ? sortAscending
                  ? "fa-solid fa-arrow-up-wide-short has-filter"
                  : "fa-solid fa-arrow-down-wide-short has-filter"
                : "fa-solid fa-arrow-up-arrow-down"
            }`
          }}</v-icon>
          <span :id="field.field">{{ field.name }}</span>
          <v-icon
            v-if="allowFiltering(field.field)"
            class="sort-icon icon-right"
            @click="toggleFilter(field.field)"
            >fa-solid fa-sliders</v-icon
          >
        </div>
        <br />
        <span :class="`summary-${field.field}`">
          <strong>{{ Object.keys(totals).includes(field.field) && totals[field.field] }}</strong>
        </span>
      </div>
    </v-row>
    <Transition name="slide">
      <div class="buttons" v-if="selectedItems.length > 0 && !hideActions">
        <p class="s" style="padding-bottom: 12px">{{ selectedItems.length }} keyword(s) selected</p>
        <div class="actionButton" v-if="!isAnalysisView">
          <MaireButton
            inverse
            :compact="true"
            :text="`Save as new analysis`"
            @click="showNewAnalysisModal = true"
          />
        </div>
        <div class="actionButton" v-if="!isAnalysisView">
          <MaireButton
            inverse
            :compact="true"
            :text="`Add keywords to analysis`"
            @click="showAnalysisModal = true"
          />
        </div>
        <div class="actionButton" v-if="isAnalysisView">
          <MaireButton
            :disabled="isViewerUser || !editAccess"
            inverse
            :compact="true"
            :text="`Remove keywords from analysis`"
            @click="showRemovalModal = true"
          />
        </div>
        <div class="actionButton">
          <MaireButton
            :disabled="isViewerUser"
            inverse
            :compact="true"
            :text="`Add label to keywords`"
            @click="showModal = true"
          />
        </div>
      </div>
    </Transition>
    <div class="tableBody">
      <div v-if="getItems.length === 0 && !isLoading" class="animation">
        <div class="center">No items found! 🤔</div>
      </div>
      <v-row
        class="item-row"
        v-for="item in getItems"
        :key="item.id"
        @click="(e: any) => handleRowClick(e, item)"
      >
        <ThreeDotMenu
          v-if="actions"
          :actions="actions.map((action) => ({ ...action, fn: () => action.fn(item) }))"
          :isOpen="threedotMenuOpen === item.id"
          @menu-opened="threedotMenuOpen = item.id"
          @menu-closed="threedotMenuOpen = undefined"
        />
        <div :class="`${actions ? 'has-menu' : ''}`"></div>
        <div class="checkbox" v-if="!hideActions">
          <v-checkbox v-model="selectedItems" :value="item" color="purple" />
        </div>
        <div
          :cols="field.width"
          :style="`flex-basis: ${field.width}%`"
          :class="`item cell cell-${field.field}`"
          v-for="field in filteredFields"
          :key="field.name"
        >
          <div class="text-center">
            <component
              v-if="field.component"
              :is="field.component()"
              v-bind="{
                fieldValue: item[field.field],
                field: field,
                item: item,
                viewId: viewId,
                selectedItems: selectedItems,
              }"
            />
            <span v-else>{{
              field.renderer &&
              field.renderer(getByDotNotation(item, field.field), item.historical_metrics?.length)
            }}</span>
          </div>
        </div>
      </v-row>
      <div class="numberSelectionSection">
        <div class="numberSelectionText inline">Results per page</div>
        <div class="numberSelector inline">
          <div class="numberSelection" v-if="!numberChoicesVisible" @click="toggleNumberChoices">
            <p>{{ elementsPerPage }}</p>
          </div>
          <div
            class="numberSelection third"
            v-if="numberChoicesVisible"
            @click="() => setElementsPerPage(20)"
          >
            <p>20</p>
          </div>
          <div
            class="numberSelection second"
            v-if="numberChoicesVisible"
            @click="() => setElementsPerPage(10)"
          >
            <p>10</p>
          </div>
          <div class="numberSelection first" v-if="numberChoicesVisible" @click="() => setElementsPerPage(5)">
            <p>5</p>
          </div>
        </div>
      </div>
      <div class="pagination">
        <div class="page-number">
          <div
            class="inline-element"
            :class="currentPage == 1 ? 'disabled' : 'page-change'"
            @click="firstPage"
          >
            <v-icon aria-label="My Account" role="img" aria-hidden="false"> mdi:mdi-page-first </v-icon>
          </div>
          <div
            class="inline-element"
            :class="currentPage == 1 ? 'disabled' : 'page-change'"
            @click="previousPage"
          >
            <v-icon aria-label="My Account" role="img" aria-hidden="false"> mdi:mdi-chevron-left </v-icon>
          </div>
          <div class="inline-element" :key="i" v-for="i in numPages(currentPage)">
            <div
              :key="i"
              v-if="i != '...'"
              v-bind:class="[i == currentPage ? 'active' : 'page-change']"
              v-on:click="changePage(i as number)"
            >
              {{ i }}
            </div>
            <div class="three-dots" v-if="i == '...'">{{ i }}</div>
          </div>
          <div
            class="inline-element"
            :class="currentPage == lastPageNum() ? 'disabled' : 'page-change'"
            @click="nextPage"
          >
            <v-icon aria-label="My Account" role="img" aria-hidden="false"> mdi:mdi-chevron-right </v-icon>
          </div>
          <div
            class="inline-element"
            :class="currentPage == lastPageNum() ? 'disabled' : 'page-change'"
            @click="lastPage"
          >
            <v-icon aria-label="My Account" role="img" aria-hidden="false"> mdi:mdi-page-last </v-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.has-menu {
  margin-left: 12px;
}
.item-header,
.item {
  flex: 1; /* You can remove the width percentage style if using flex */
  padding-right: 10px; /* maintain some spacing between columns, adjust as needed */
}

/* Ensure last child has no right padding, if desired */
.item-header:last-child,
.item:last-child {
  padding-right: 0;
}
.slide-leave-active,
.slide-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter-to,
.slide-leave-from {
  max-height: 500px;
  opacity: 1;
  height: 100%;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 24px;
  opacity: 0;
  height: 0px;
}
.sort-icon {
  /* display: inline-block; */
  /* margin-right: 10px; */
  font-size: 12px;
  cursor: pointer;
}
.header-title-content {
  display: flex; /* Use Flexbox to lay out items in a row */
  align-items: center; /* Center items vertically within the container */
  justify-content: flex-start; /* Place the first icon to the start and the other to the end */
}
.header-title-content > span {
  margin-left: 10px; /* Margin between the first icon and the span */
}
.header-title-content > .icon-right {
  margin-left: auto; /* Pushes the second icon to the far right */
  margin-right: 10px;
}
.v-leave-from {
  opacity: 1 !important;
}
.v-leave-to {
  opacity: 0 !important;
}
.v-enter-active {
  transition: opacity 0.5s ease !important;
}
.v-leave-active {
  transition: opacity 0.5s ease !important;
}
.item-header {
  display: relative;
}
.has-filter .header-title,
.has-filter strong,
.has-filter,
.has-filter i,
i.has-filter {
  color: rgb(var(--v-theme-mairePurple)) !important;
}
.filter {
  width: 500px;
  right: 0px;
  margin-right: -250px;
  z-index: 99999;
  padding-top: 0px;
  top: 100%;
  border-radius: 5px;
  position: absolute;
  word-break: normal;
  opacity: 1;
  transition: opacity 0.2s ease;
}
.labelModal input {
  color: teal !important;
}
.buttons {
  /* position: fixed;
  bottom: 24px;
  right: 24px; */
  z-index: 2;
  height: 100%;
}
.labelButton,
.actionButton {
  position: relative;
  display: inline-block;
  padding-right: 12px;
}
.checkbox {
  position: relative;
  min-height: 50px;
  max-width: 30px;
  margin-left: 0px;
  padding: 0px !important;
  display: flex;
  align-items: center;
  /* padding: -24px !important;
  margin-top: -12px !important; */
}
.summary-ctr::after {
  content: "%";
}
.summary-potential_reached::after {
  content: "%";
}

.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-from,
.v-enter-from {
  opacity: 0;
}

.search_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 400px;
  float: right;
}

.search_bar {
  width: 400px;
  float: right;
}

.animation {
  padding: 50px;
}

.table-container {
  height: 100%;
  padding-top: 12px;
  margin-bottom: 100px;
}

.cell {
  padding: 0 0 0 10px;
  display: table;
  word-break: break-word;
  border-right: 1px solid rgb(var(--v-theme-maireGrey3));
}

.cell:last-child {
  border-right: none;
}

.text-center {
  display: table-cell;
  overflow: hidden;
  vertical-align: middle;
  text-align: left !important;
}

.progress-bar {
  filter: opacity(0.2);
}
.mdi:mdi-star {
  color: gold;
}

.disabled {
  filter: opacity(0.4);
}

.actions {
  float: right;
}

.table {
  font-size: 12px;
  min-height: 300px;
  position: relative;
}

.tableBody {
  padding-top: 12px;
  margin-top: 24px;
  height: 100%;
  overflow-y: auto;
}

.tableBody::-webkit-scrollbar {
  display: none;
}

.page-change {
  cursor: pointer;
}

.inline-element {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  height: 30px;
  width: 30px;
}

.inline {
  display: inline;
}

.numberSelectionSection {
  position: absolute;
  right: 0px;
  bottom: -30px;
}

.numberSelector {
  position: absolute;
  bottom: -5px;
  float: right;
  right: 0px;
  width: 30px;
}

.numberSelection {
  border: black 1px solid;
  background: white;
  margin-top: -1px;
  padding: 0px;
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  display: table;
}

.numberSelection p {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.numberSelectionText {
  margin-right: 40px;
  position: absolute;
  bottom: 0px;
  float: right;
  right: 0px;
  width: 100px;
}

.page-number {
  display: inline-table;
  text-align: center;
  padding: 0px;
  text-anchor: end;
  position: relative;
  bottom: 0px;
}

.active {
  background-color: #68d8b1;
  height: 28px;
  width: 28px;
  border: black 1px solid;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.item-row:hover strong {
  color: white !important;
}

.errorMessage {
  display: block;
  margin: auto;
  color: purple;
}

.pagination {
  position: absolute;
  float: right;
  bottom: -90px;
  right: 0px;
}

.item-header-row {
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
  position: relative;
  margin-left: 0px;
  padding: 16px 0px !important;
}

.item-row {
  background-color: white;
  border-radius: 10px;
  margin-left: 0px;
  text-align: left;
  min-height: 40px;
  padding: 4px;
  width: 100%;
  margin-bottom: 0px;
  cursor: pointer;
}

.item-row:hover {
  background-color: rgb(var(--v-theme-maireDarkBlue));
  border-width: 2px;
}

.item-row:hover span,
.item-row:hover div,
.item-row:hover i {
  color: white;
}
</style>
<script lang="ts">
import { Field } from "@/types";
import { defineComponent, PropType } from "vue";

const sortColumns = (a: any, b: any, sortColumn: string): number => {
  if (sortColumn === "position") {
    return (a[sortColumn] || 102) - (b[sortColumn] || 102);
  }
  if (typeof a[sortColumn] === "string") {
    return (a[sortColumn] as string).localeCompare(b[sortColumn]?.toString() as string);
  } else if (typeof a[sortColumn] === "number") {
    return (a[sortColumn] ?? 0) - (b[sortColumn] ?? 0);
  } else if (Object.keys(a[sortColumn] || {}).includes("high")) {
    return (a[sortColumn] as unknown as CPC).high - (b[sortColumn] as unknown as CPC).high;
  } else if (typeof a[sortColumn] === "object") {
    const aVal = JSON.stringify(a[sortColumn]?.[0]?.text ?? a[sortColumn]?.[0] ?? "");
    const bVal = JSON.stringify(b[sortColumn]?.[0]?.text ?? b[sortColumn]?.[0] ?? "");
    return (aVal as string)?.localeCompare(bVal?.toString() as string);
  } else {
    return (a[sortColumn] ?? (0 as number)) - (b[sortColumn] ?? (0 as number));
  }
};

export interface Item {
  [key: string]: any;
}
// import ActionIcon from "@/components/DataTable/ActionIcon.vue";
import MaireSelect from "@/components/MaireSelect/MaireSelect.vue";
import ThreeDotMenu from "@/components/ThreeDotMenu/ThreeDotMenu.vue";
import { Action } from "@/components/ThreeDotMenu/types";
import { AnalysisDetails, CheckboxState } from "@/store/modules/analysis/types";
import WaitAnimation from "../WaitAnimation/WaitAnimation.vue";
import { numberFormatter } from "../helpers/helpers";
import MaireButton from "../ButtonBlock/MaireButton.vue";
import MaireDialog from "../MaireDialog/MaireDialog.vue";
import KeywordFilter from "../KeywordFilters/KeywordFilter.vue";
import CreateAnalysisFilterModal from "@/components/CreateAnalysisFilterModal/CreateAnalysisFilterModal.vue";
import { useStore } from "@/store";
import { CPC } from "@/store/modules/keywords/types";
export default defineComponent({
  name: "DataTable",
  components: {
    KeywordFilter,
    WaitAnimation,
    MaireSelect,
    MaireButton,
    MaireDialog,
    CreateAnalysisFilterModal,
    ThreeDotMenu,
  },
  props: {
    items: { required: true, type: Object as () => Item[] },
    fields: { required: true, type: Object as () => Field[] },
    isLoading: { required: false, type: Boolean, default: false },
    magicSearchEnabled: { required: false, type: Boolean, default: false },
    hideActions: { required: false, type: Boolean, default: false },
    actions: { required: false, type: Array as PropType<Action[]> },
    viewId: { required: false, type: Number },
    searchFields: {
      required: false,
      type: Array as () => string[],
      default: () => ["keyword", "labels"],
    },
    handleRowClick: { required: false, type: Function, default: () => {} },
    hideFields: {
      required: false,
      type: Array as () => string[],
      default: () => [],
    },
  },
  watch: {
    filterItemsBySearch() {
      if (this.currentPage > this.maxPages || this.currentPage === 0) {
        this.currentPage = 1;
      }
      this.selectedItems = [];
    },
    localFilters: {
      handler() {
        this.filteredItems = this.items.filter((item) => {
          return Object.entries(this.localFilters)
            .filter(([, values]) => values.length)
            .every(([field, values]) => {
              const value = item?.[field]
              if (typeof value === "object") {
                const thisField = this.fieldMap?.[field];
                const itemValues =
                value?.map((row: any) => row?.[thisField?.filtering_key] ?? row) ?? [];
                return itemValues.some((itemValue: any) => values.includes(itemValue));
              }
              return values.includes(value);
            });
        });
      },
      deep: true,
    },
    currentAnalysis() {
      if (this.currentAnalysis?.id) {
        this.selectedAnalysis = this.currentAnalysis;
        this.isAnalysisView = true;
      } else {
        this.selectedAnalysis = undefined;
        this.isAnalysisView = false;
      }
    },
    items() {
      this.selectedItems = [];
      this.filteredItems = this.items;
      setTimeout(this.calculateTotals, 0);
      if (this.currentPage > this.maxPages) {
        this.currentPage = 1;
      }
    },
  },
  data: () => ({
    visibleFilter: "",
    numberChoicesVisible: false,
    sortAscending: false,
    isAnalysisView: false,
    localFilters: {} as { [key: string]: CheckboxState[] },
    selecteditem: {} as Item,
    selectedItems: [] as Item[],
    sortColumn: "potential",
    elementsPerPage: 10,
    currentPage: 1,
    threedotMenuOpen: undefined,
    showModal: false,
    showNewAnalysisModal: false,
    showAnalysisModal: false,
    showRemovalModal: false,
    selectedAnalysis: undefined as AnalysisDetails | undefined,
    labelToAdd: "",
    totals: {},
    filteredItems: [] as Item[],
  }),
  mounted() {
    this.filteredItems = this.items;
    if (this.filteredItems.length) {
      this.calculateTotals();
    }
    if (this.currentAnalysis?.id) {
      this.selectedAnalysis = this.currentAnalysis;
      this.isAnalysisView = true;
    }
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    currentAnalysis() {
      return this.store.state.analysis.currentAnalysis;
    },
    allowedLocalFilters() {
      return this.fields.filter((field) => field?.allow_filtering).map((field) => field.field);
    },
    analysisLabels() {
      if (!this.currentAnalysis?.id) {
        return Object.values(this.store.state.labels.labels) ?? [];
      }
      return this.store.state.labels.analysisLabels?.[this.currentAnalysis?.id] ?? [];
    },
    searchWord: {
      get() {
        return this.store.state.searchWord;
      },
      set(value: string) {
        this.store.commit("analysis/setSearchWord", value);
      },
    },
    filters() {
      return this.store.state.analysis.currentFilters;
    },
    analysisFilters() {
      return this.store.state.analysis.analyses;
    },
    filteredFields() {
      return this.fields.filter((field) => !this.hideFields.includes(field.field));
    },
    fieldMap() {
      return Object.fromEntries(this.fields.map((field) => [field.field, field]));
    },
    errorText() {
      return this.store.state.errorText;
    },
    publicPath() {
      return process.env.BASE_URL;
    },
    maxPages() {
      return Math.ceil(this.filteredItems.length / this.elementsPerPage);
    },
    getItems() {
      var start = (this.currentPage - 1) * this.elementsPerPage;
      var end = start + this.elementsPerPage;
      let itemsCopy = [...this.filteredItems] as Item[];
      if (this.sortAscending) {
        return itemsCopy.sort((a, b) => sortColumns(a, b, this.sortColumn as string)).slice(start, end);
      } else {
        return itemsCopy
          .sort((a, b) => sortColumns(a, b, this.sortColumn as string))
          .reverse()
          .slice(start, end);
      }
    },
    allSelected() {
      return this.selectedItems.length > 0 && this.selectedItems.length === this.filteredItems.length;
    },
    labelFilterActive() {
      const fltrs = this.filters.labelFilters;
      const includeLabels = fltrs?.include.labels.filter((fltr) => fltr.checked).length > 0;
      return includeLabels;
    },
    columnsWithFilters() {
      const columns = [];
      if (this.filters.volumeRange?.min || this.filters.volumeRange?.max) {
        columns.push("volume");
      }
      if (this.filters.trendingFilter.some((trend) => trend.checked)) {
        columns.push("trending");
      }
      if (this.filters.positionRange?.min || this.filters.positionRange?.max) {
        columns.push("position");
      }
      if (this.filters.languageFilter.some((lang) => lang.checked)) {
        columns.push("localization.language_code");
      }
      if (this.filters.countryFilter.some((country) => country.checked)) {
        columns.push("localization.location_name");
      }
      if (this.labelFilterActive) {
        columns.push("labels");
      }
      if (this.searchWord) {
        columns.push("keyword");
      }
      Object.entries(this.localFilters).forEach(([field, values]) => {
        if (values.length > 0) {
          columns.push(field);
        }
      });
      return columns;
    },
    selectedItemIds() {
      return this.selectedItems.map((item) => item.id);
    },
    userType() {
      return this.store.state.user.type;
    },
    isViewerUser() {
      return this.userType === "viewer";
    },
    editAccess() {
      return this.currentAnalysis?.edit_access;
    },
  },
  methods: {
    afterFilterSave(data: { field: string; cbs: CheckboxState[] }) {
      // This only affects the table, so not suitable for e.g. analysis views where the keyword data is used as the source for other components as well.
      // e.g. graphs in the analysis page
      this.localFilters[data.field] = data.cbs;
      this.visibleFilter = "";
    },
    async calculateTotals() {
      let totals = {} as any;
      this.fields.forEach((field) => {
        if (field.aggregation === "sum") {
          const totalValue = numberFormatter(
            this.filteredItems.reduce((a, b) => {
              return a + (b?.[field.field] as number);
            }, 0),
            2
          );
          totals[field.field] = `${totalValue}${field?.postFix || ""}`;
        } else if (field.aggregation === "average") {
          let totalValue = 0;
          const itemsWithNumericValues = this.filteredItems.filter(
            (item) =>
              typeof item?.[field.field] === "number" ||
              parseFloat(item?.[field.field]) ||
              field.field === "cpc"
          );
          itemsWithNumericValues.forEach((item) => {
            let fieldValue = 0;
            if (field.field.toLowerCase() === "cpc") {
              fieldValue = parseFloat(item?.[field.field]?.high || "0");
            } else {
              fieldValue = parseFloat(item?.[field.field]);
            }
            totalValue += fieldValue;
          });
          let avgResult = totalValue / itemsWithNumericValues.length;
          /* If percentage, we multiply by 100 */
          if (field.field === "ctr" || field.field === "potential_reached") {
            avgResult = avgResult * 100;
          }
          totals[field.field] = `${numberFormatter(avgResult, 2)}${field?.postFix || ""}`;
        } else if (field.aggregation === "weighted_average_impressions") {
          let totalValue = 0;
          let totalImpressions = 0;
          this.filteredItems.forEach((item) => {
            totalValue += item?.[field.field] * item?.["impressions"];
            totalImpressions += item?.["impressions"] || item?.["volume"];
          });
          let avgResult = totalValue / totalImpressions;
          /* If percentage, we multiply by 100 */
          if (field.field === "ctr" || field.field === "potential_reached" || field.field === "trending") {
            avgResult = avgResult * 100;
          }
          totals[field.field] = `${numberFormatter(avgResult, 2)}${field?.postFix || ""}`;
        } else if (field.aggregation === "count_distinct") {
          const uniqueValues = new Set(this.filteredItems.map((a) => a?.[field.field])).size;
          totals[field.field] = `${uniqueValues} unique`;
        } else {
          totals[field.field] = "";
        }
      });
      this.totals = totals;
    },
    handleAnalysisSave(analysisDetails: AnalysisDetails) {
      this.store.dispatch("analysis/createOrUpdateAnalysis", {
        ...analysisDetails,
        localized_keyword_ids: this.selectedItemIds,
      });
    },
    allowFiltering(field: string) {
      const allowedFilters = [
        ...this.allowedLocalFilters,
        "volume",
        "trending",
        "potential",
        "position",
        "localization.language_code",
        "localization.location_name",
      ];
      return allowedFilters.includes(field);
    },
    getByDotNotation(obj: object, path: string) {
      return path.split(".").reduce((currentObject: { [key: string]: any }, key) => {
        return currentObject ? currentObject[key] : undefined;
      }, obj);
    },
    handleFilterSelect(selectedItem: any) {
      if (selectedItem?.name) {
        this.selectedAnalysis = selectedItem;
      }
    },
    toggleFilter(field: string) {
      if (this.visibleFilter === field) {
        this.visibleFilter = "";
      } else {
        this.visibleFilter = field;
      }
    },
    addLabelToKeywords() {
      // @ts-ignore
      const labelToAdd = typeof this.labelToAdd === "string" ? this.labelToAdd.trim() : this.labelToAdd.text;
      this.store.dispatch("keywords/addLabelToKeywords", {
        label: labelToAdd,
        kwIds: this.selectedItemIds,
        viewId: this.viewId,
      });
      this.showModal = false;
    },
    addKeywordsToAnalysis() {
      this.store.dispatch("analysis/addKeywordsToAnalysis", {
        kws: this.selectedItems,
        filter: this.selectedAnalysis,
        skipIfEmpty: true,
      });
      this.showAnalysisModal = false;
    },
    removeKeywordsFromAnalysis() {
      this.store.dispatch("analysis/removeKeywordsFromAnalysis", {
        kwIds: this.selectedItemIds,
        filter: this.selectedAnalysis,
      });
      this.showRemovalModal = false;
    },

    toggleAll() {
      if (this.selectedItems.length > 0) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.filteredItems;
      }
    },
    sortItems(field: string) {
      const sortColumn = field;
      if (this.sortColumn === sortColumn) {
        this.sortAscending = !this.sortAscending;
      } else {
        this.sortAscending = false;
        this.sortColumn = sortColumn;
      }
    },
    toggleNumberChoices() {
      this.numberChoicesVisible = !this.numberChoicesVisible;
    },
    setElementsPerPage(numberOfElements: number) {
      this.elementsPerPage = numberOfElements;
      this.numberChoicesVisible = false;
    },
    numPages(currentPage: number) {
      let pages = [1, currentPage - 1, currentPage, currentPage + 1, this.maxPages];
      pages = pages.filter((page) => page > 0 && page <= this.maxPages);
      const pageNumbers = Array.from(new Set(pages.sort((a, b) => a - b)));
      let prevNumb = 0;
      let pageNumbersWithDots = [];
      for (const numb of pageNumbers) {
        if (numb - prevNumb > 1) {
          pageNumbersWithDots.push("...");
        }
        pageNumbersWithDots.push(numb);
        prevNumb = numb;
      }
      return pageNumbersWithDots;
    },
    lastPageNum() {
      return Math.ceil(this.filteredItems.length / this.elementsPerPage);
    },
    nextPage() {
      if (this.currentPage != this.lastPageNum()) {
        this.currentPage = this.currentPage + 1;
      }
    },
    previousPage() {
      if (this.currentPage != 1) {
        this.currentPage = this.currentPage - 1;
      }
    },
    lastPage() {
      this.currentPage = this.lastPageNum();
    },
    firstPage() {
      this.currentPage = 1;
    },
    changePage(page: number) {
      this.currentPage = page;
    },
  },
});
</script>
