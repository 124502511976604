<template>
  <div
    v-if="showSelect"
    :class="`select ${expanded || filterInUse ? 'expanded' : ''} ${disabled ? 'disabled' : ''}`"
  >
    <div :class="`select-content`" @click="!disabled && (expanded = !expanded)">
      <p class="content-text">{{ getSelectText }}</p>
      <v-icon
        :icon="`${expanded ? 'mdi:mdi-chevron-up' : 'mdi:mdi-chevron-down'}`"
        class="caret-icon"
      ></v-icon>
    </div>
  </div>
  <div v-if="expanded || !showSelect" :class="`select-options ${type === 'checkbox' ? 'small' : ''} ${transparent ? 'transparent' : ''}`">
    <RangeSlider v-if="type === 'slider'" @change="handleSliderChange"  @save="handleVolumeSave" :sliderState="sliderState" />
    <ul v-if="type === 'select'" class="select-options-list overflow-y-auto">
      <li
        v-for="item in items"
        :key="field && typeof item === 'object' ? item[field] : item"
        @click="() => handleSave(item)"
        class="select-option"
      >
        {{ field && typeof item === "object" ? item[field] : item }}
      </li>
    </ul>
    <CheckboxGroup @change="handleCheckboxChange" v-if="type === 'checkbox'" :checkboxes="checkboxes" />
  </div>
</template>
<style scoped>
.select {
  position: relative;
  border: 1px solid rgb(var(--v-theme-maireGrey2));
  border-radius: 12px;
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Space between text and icon */
  padding: 2px 12px; /* Add some padding */
  cursor: pointer; /* Change cursor on hover */
  margin: 12px 0px;
}
.select.disabled {
  cursor: not-allowed;
}
.select-options-list {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
.select-option {
  padding: 12px;
  cursor: pointer;
}
.select-option:hover {
  background-color: rgb(var(--v-theme-mairePurple));
  color: white;
}
.select.expanded {
  border: 1px solid rgb(var(--v-theme-mairePurple));
}

i {
  color: rgb(var(--v-theme-maireGrey2));
}
.expanded i {
  color: rgb(var(--v-theme-mairePurple));
}

.select-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-text {
  color: rgb(var(--v-theme-maireGrey2));
  margin: 0;
  padding-right: 24px;
  flex-grow: 1; /* Allow text to expand to fill available space */
  overflow: hidden; /* Hide overflowed text */
  white-space: nowrap; /* Ensure text doesn't wrap */
}
.expanded .content-text {
  color: rgb(var(--v-theme-mairePurple));
}

.select-options {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 12px 0px #8200FF12;
  /* padding-bottom: 24px; */
  /* width: 300px; */
  min-width: 300px;
  z-index: 2;
  max-height: 400px;
  overflow: auto;
}
.select-options.transparent {
  background-color: inherit;
}
.small {
  width: inherit;
  padding: 12px;
}
</style>
<script lang="ts">
import { PropType, defineComponent } from "vue";
// import { numberFormatter } from "@/components/helpers/helpers";
import RangeSlider from "@/components/RangeSlider/RangeSlider.vue";
import CheckboxGroup from "@/components/CheckboxGroup/CheckboxGroup.vue";
import { numberFormatter } from "../helpers/helpers";
import { RangeSliderState, CheckboxState } from "@/store/modules/analysis/types";

interface IFunction {
  // eslint-disable-next-line no-unused-vars
  (...args: any[]): any;
}

interface Item {
  [key: string]: any;
}

export default defineComponent({
  name: "MaireSelect",
  emits: ["save", "change"],
  props: {
    type: {
      type: String,
      required: false,
      default: "slider",
    },
    title: String,
    postFix: {
      type: String,
      default: "",
      required: false,
    },
    handleSave: {
      type: Function as PropType<IFunction>,
      required: true,
    },
    numberFormatter: {
      type: Function as PropType<IFunction>,
      default: numberFormatter,
      required: false,
    },
    disabled: { type: Boolean, required: false, default: false },
    field: { type: String, required: false },
    sliderState: { type: Object as PropType<RangeSliderState>, required: false, default: () => {} },
    checkboxes: { type: Array as PropType<CheckboxState[]>, required: false, default: () => [] },
    items: { type: Array as PropType<string[] | Item[]>, required: false },
    selectedItem: { type: String, required: false },
    showSelect: { type: Boolean, required: false, default: true },
    transparent: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      expanded: false,
      // Local state to store the slider values before they are saved
      slider: [undefined, undefined] as (number | undefined)[],
      selectedCheckboxes: [] as string[],
    };
  },
  computed: {
    filterInUse() {
      if (this.type === "slider") {
        if (
          this.sliderState?.rangeMin === this.sliderState?.min &&
          this.sliderState?.rangeMax === this.sliderState?.max
        ) {
          return false;
        }
      }
      return (
        this.sliderState?.min ||
        (this.sliderState?.max && this.sliderState?.max < this.sliderState.rangeMax) ||
        this.checkboxes?.some((checkbox) => checkbox.checked)
      );
    },
    getSelectText() {
      // if (this.type === "slider") {
      //   if (!(this.slider?.[0] || this.slider?.[1])) {
      //     return this.title;
      //   }
      // }
      if (this.type === "checkbox") {
        if (!this.checkboxes?.filter((cb) => cb.checked).length) {
          return this.title;
        } else {
          return `${this.title}: ${this.checkboxes
            ?.filter((cb) => cb.checked)
            .map((cb) => cb.text)
            .join(", ")}`;
        }
      }
      if (this.type === "select") {
        return this.selectedItem || this.title;
      }
      const hasMin = this.sliderState?.min && this.sliderState?.min > 0;
      const hasMax = this.sliderState?.max && this.sliderState?.max < this.sliderState.rangeMax;
      if (!hasMin && !hasMax) {
        return this.title;
      }
      return `${this.title}: ${this.numberFormatter(this.sliderState?.min || 0, 2)}${
        this.postFix
      } - ${this.numberFormatter(this.sliderState?.max || this.sliderState.rangeMax, 2)}${this.postFix}`;
    },
  },
  mounted() {
    window.addEventListener("click", this.handlePageClick);
    if (this.type === "slider") {
      if ((this.sliderState && this.sliderState?.min) || this.sliderState?.max) {
        const min = this.sliderState?.min || 0;
        const max = this.sliderState?.max || this.sliderState.rangeMax;
        this.slider = [min, max];
      }
    }
  },
  unmounted() {
    window.removeEventListener("click", this.handlePageClick);
    if (!this.showSelect) {
      const dataToSave = this.type === "slider" ? this.slider : this.selectedCheckboxes;
      this?.handleSave(dataToSave);
    }
  },
  methods: {
    // numberFormatter,
    handleVolumeSave(slider: number[]){
      this.$emit("save", slider);
      this?.handleSave(slider);
    },
    handlePageClick(e: any) {
      if (!this.expanded && this.showSelect) {
        return;
      }
      const allowedClasses = [
        "v-slider",
        "mdi-chevron-up",
        "content-text",
        "select-options",
        "input",
        "v-field",
        "fa-sliders",
        "select-content",
        "btn",
      ];
      const isInput = e.target?.localName?.toLowerCase() === "input";
      const allowedClick =
        isInput || allowedClasses.some((className) => e.target?.className?.includes(className));
      if (!allowedClick && (this.expanded || !this.showSelect)) {
        this.expanded = false;
        const dataToSave = this.type === "slider" ? this.slider : this.selectedCheckboxes;
        this.$emit("save", dataToSave);
        this?.handleSave(dataToSave);
      }
    },
    handleSliderChange(value: any) {
      // can be an event triggered by one of the number min/max fields, or a value from the rangeslider. We ignore the checkbox events
      // as they are already passed to the slider which is being watched
      if (value?.length === 2) {
        this.$emit("change", value)
        this.slider = value;
      }
    },
    handleCheckboxChange(value: string[] | any) {
      // check if event
      if (value?.target?.value) {
        return;
      }
      this.selectedCheckboxes = value;
    },
  },
  components: {
    RangeSlider,
    CheckboxGroup,
  },
});
</script>
